import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './header.css'

const OTPBox = ({contact,setContact,handleshowError,setError,setShowotp}) => {
    const state = useLocation();

    const navigate = useNavigate();
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [count, setcount] = useState(0)
    // const handleChange = (element, index) => {
    //     if (isNaN(element.value)) return false;
    //     setOtp([...otp1.map((d, idx) => (idx === index ? element.value : d))]);
    //     //Focus next input
    //     if (element.nextSibling) {
    //         element.nextSibling.focus();
    //     }
    // };
    const resend = async (e) => {
        // e.preventDefault();
        setcount(30)
        let payload = { contact }
        console.log(payload)
        let result = await fetch('https://node.firehrm.com/FB/flame/admin/login',
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/JSON'
                },
                boedy: JSON.stringify(payload)
            })
        console.log(result)
        if (result.status == 200 || result.status == 204) {
            // alert("ReSend successfully")
            // window.location.reload(false)
            // if()
            setcount(30)
            setShowotp(false)
            // handleCloseotp(e);
        }
        else{
            setError("Something went wrong")
            setShowotp(false)
            handleshowError(e);
        }
    }
    async function verify(e) {
        e.preventDefault()
        setcount(0)
        // let contact = state.state;
        // let otp = otp1.join('');
        let payload = { contact, otp }
        console.log(payload)
        let result = await fetch('https://node.firehrm.com/FB/flame/admin/login',
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/JSON'
                },
                body: JSON.stringify(payload)
            })
        console.log(result)
        const res = await result.json()
        console.log(res)
        if (result.status == 200 || result.status == 204) {
            let token = res.token;
            let UserID = res.datas._id;
            console.log(UserID, token)
            localStorage.setItem("userId", UserID);
            localStorage.setItem("userName", res.datas.name)
            localStorage.setItem("usertoken", token);
            const token2 = localStorage.getItem('usertoken')
            setcount(0)
            // setError("Something went wrong")
            setShowotp(false)
            // handleshowError(e);
            // window.alert("Login Successfull")
            // navigate('/')
            // window.location.reload(false);
        }
        else {
            setError("Something went wrong")
            setShowotp(false)
            handleshowError(e);
        }
    }
    const tick = () => {
        setcount(count - 1);
    };
    useEffect(()=> {
        if (count === 0) return;
        const interval = setInterval(tick, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [count]);
    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label>Mobile</label>
                        <input type="email" className="form-control" name="email" id="email" value={contact} onChange={(e) => setContact(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>One Time Password</label>
                        <input type="number" className="form-control" name="otp" id="otp" onChange={(e) => setOtp(e.target.value)} />
                    </div>
                    {/* {otp1.map((data, index) => {
                        return (
                            <input
                                className="otp-field"
                                type="text"
                                name="otp"
                                maxLength="1"
                                key={index}
                                value={data}
                                onChange={e => handleChange(e.target, index)}
                                onFocus={e => e.target.select()}
                            />
                        );
                    })} */}
                    {/* <p>OTP Entered - {otp1.join("")}</p> */}
                    {count!=0 &&  <p className="text-center ">Resend OTP in {count} sec</p>}
                    {/* <p className=""></p> */}
                    <p>
                        {/* <button
                            className="btn btn-secondary me-2"
                            onClick={e => setOtp([...otp1.map(v => "")])}> Clear </button> */}
                        {count == 0 ? <button
                            className="verifyOtp form-control"
                            disabled> Verify OTP</button> :
                            <button
                                className="verifyOtp form-control"
                                onClick={(e) => { verify(e) }}> Verify OTP</button>}
                        {count == 0 ? <button
                            className="verifyOtp form-control"
                            onClick={(e) => { resend(e) }}>Send OTP</button>
                            : <button
                                className="verifyOtp form-control" disabled
                            > Send OTP</button>}
                    </p>
                </div>
            </div>
        </>
    );
};

export default OTPBox;