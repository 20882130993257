import React, { useState, useEffect } from 'react';
import Footer from '../Footer/footer';
import Header from '../Header/header';
import './blog.css'
import { Helmet } from 'react-helmet';
import axios from 'axios';
import blogsingle from "../../assets/img/blog-single.jpg";
import Avatar1 from "../../assets/img/avatar1.jpg";
import avatar4 from "../../assets/img/avatar4.jpg";
import avatar6 from "../../assets/img/avatar6.jpg";
import blog4 from "../../assets/img/blog-4.jpg";
import blog6 from "../../assets/img/blog-6.jpg";
import { useLocation } from 'react-router-dom';
import blog5 from "../../assets/img/blog-5.jpg";
import { get } from 'jquery';
const token = localStorage.getItem('token');
// console.log(token, 'token')


function BlogDetails() {
    const { state } = useLocation();
    const blogId = state._id
    console.log(blogId, 'state')

    const [getcomments, setGetComments] = useState([])
    const [getblog, setBlog] = useState([])
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');


    useEffect(() => {
        GetComments();
        GetSingleBlog();
    }, []);

    const GetSingleBlog = async () => {
        axios({
            'method': 'GET',
            'url': 'https://node.firehrm.com/FB/flame/blog/getSingleBlog/' + blogId,
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response.data.message, 'singleblog')
                setBlog(response.data.message[0])
                // console.log(getblog.commands)
            })
            .then
            .catch((error) => {
                alert(error)
            }
            )
    }
    const GetComments = async () => {
        axios({
            'method': 'GET',
            'url': 'https://node.firehrm.com/FB/flame/blog/getCommentByBlogId/' + blogId,
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response.data.message, 'comments')
                setGetComments(response.data.message)
            })
            .then
            .catch((error) => {
                alert(error)
            }
            )
    }
    const AddComment = (e) => {
        e.preventDefault();
        let payload = { email, comment, name, blogId }
        // console.log(payload)
        fetch('https://node.firehrm.com/FB/flame/blog/addComment/' + blogId,
            {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    'Authorization': token
                },
                body: JSON.stringify(payload)
            }).then(res => {
                // console.log(res, "res")
                if (res.status == 200 || res.status == 204 || res.status == 201) {
                    window.location.reload(false)
                }
            })
    }
    return (
        <div>
            <Header />

            <main className='blog-top'>
                <div className="page_header element_to_stick">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
                                <div className="breadcrumbs blog">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#">Category</a></li>
                                        <li>Page active</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5 col-md-5">
                                <div className="search_bar_list">
                                    <input type="text" className="form-control" placeholder="Search in blog..." />
                                    <input type="submit" value="Search" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container margin_30_40">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="singlepost">
                                <div>
                                    <figure><img alt="" className="img-fluid" src={getblog.blogImage} /></figure>
                                    <h1>{getblog.blogName}</h1>
                                    <div className="postmeta">
                                        <ul>
                                            <li><a href="#"><i className="icon_folder-alt"></i> Category</a></li>
                                            <li><i className="icon_calendar"></i>{getblog.createdAt}</li>
                                            <li><a href="#"><i className="icon_comment_alt"></i> (14) Comments</a></li>
                                        </ul>
                                    </div>
                                    <div className="post-content">
                                        <div >
                                            <p>{getblog.content}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div id="comments">
                                <h5>Comments</h5>
                                <ul>
                                    {getcomments?.map(x =>

                                        <li>
                                            <div className="comment_right clearfix">
                                                <div className="comment_info">
                                                    By <a href="#">{x.name}</a><span>|</span>
                                                    <span>{x.createdAt}</span>
                                                </div>
                                                <p>
                                                    {x.comment}
                                                </p>
                                            </div>

                                        </li>

                                    )}

                                </ul>

                            </div>

                            <hr />

                            <h5>Leave a comment</h5>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <input type="text" name="name" id="name2" className="form-control" placeholder="Name" onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <input type="text" name="email" id="email2" className="form-control" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>

                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="comments" id="comments2" rows="6" placeholder="Comment" onChange={(e) => setComment(e.target.value)}></textarea>
                            </div>
                            <div className="form-group">
                                <button type="submit" id="submit2" className="btn_1 add_bottom_15" onClick={AddComment}>Submit</button>
                            </div>

                        </div>

                        <aside className="col-lg-3">
                            <div className="widget">
                                <div className="widget-title first">
                                    <h4>Latest Post</h4>
                                </div>
                                <ul className="comments-list">
                                    <li>
                                        <div className="alignleft">
                                            <a href="#0"><img src={blog5} alt="" /></a>
                                        </div>
                                        <small>Category - 11.08.2016</small>
                                        <h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
                                    </li>
                                    <li>
                                        <div className="alignleft">
                                            <a href="#0"><img src={blog6} alt="" /></a>
                                        </div>
                                        <small>Category - 11.08.2016</small>
                                        <h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
                                    </li>
                                    <li>
                                        <div className="alignleft">
                                            <a href="#0"><img src={blog4} alt="" /></a>
                                        </div>
                                        <small>Category - 11.08.2016</small>
                                        <h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
                                    </li>
                                </ul>
                            </div>
                            <div className="widget">
                                <div className="widget-title">
                                    <h4>Categories</h4>
                                </div>
                                <ul className="cats">
                                    <li><a href="#">Food <span>(12)</span></a></li>
                                    <li><a href="#">Places to visit <span>(21)</span></a></li>
                                    <li><a href="#">New Places <span>(44)</span></a></li>
                                    <li><a href="#">Suggestions and guides <span>(31)</span></a></li>
                                </ul>
                            </div>
                            <div className="widget">
                                <div className="widget-title">
                                    <h4>Popular Tags</h4>
                                </div>
                                <div className="tags">
                                    <a href="#">Food</a>
                                    <a href="#">Bars</a>
                                    <a href="#">Cooktails</a>
                                    <a href="#">Shops</a>
                                    <a href="#">Best Offers</a>
                                    <a href="#">Transports</a>
                                    <a href="#">Restaurants</a>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>

            </main>
            <Footer />

            <Helmet>
                <script src="assets/js/common_scripts.min.js"></script>
                <script src="assets/js/common_func.js"></script>
                <script src="assets/assets/validate.js"></script>
            </Helmet>
        </div>
    )
}

export default BlogDetails