import React, { useEffect, useState } from 'react';
import Header from '../Header/header';
import location_1 from '../../assets/img/location_1.jpg'
import location_2 from '../../assets/img/location_2.jpg'
import location_3 from '../../assets/img/location_3.jpg'
import location_4 from '../../assets/img/location_4.jpg'
import location_5 from '../../assets/img/location_5.jpg'
import location_6 from '../../assets/img/location_6.jpg'
import location_7 from '../../assets/img/location_7.jpg'
import location_8 from '../../assets/img/location_8.jpg'
import location_9 from '../../assets/img/location_9.jpg'
import location_10 from '../../assets/img/location_10.jpg'
import location_11 from '../../assets/img/location_11.jpg'
import location_12 from '../../assets/img/location_12.jpg'
import Footer from '../Footer/footer';
import { Helmet } from "react-helmet"
import { useLocation, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import './grid.css'
import $ from 'jquery'
import axios from 'axios';

const Grid_listing_filterscol_full_masonry = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	console.log(state)
	const [response, setResponse] = useState([])
	const [categoryResponse, setCategory] = useState([])
	const [category, setCategoryspecific] = useState([])
	const [rating, setrating] = useState([])
	const [distance, setDistance] = useState(0)
	const [price1, setprice] = useState([]);
	const latitude = localStorage.getItem('latitude');
	const longitude = localStorage.getItem('longitude');
	const [count, setcount] = useState(2)
	const [filteredResults, setFilteredResults] = useState([]);
	const [checked, setChecked] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const [currentPage, setCurrentPage] = useState(1)
	// async function All() {
	// 	const latitude = localStorage.getItem('latitude');
	// 	const longitude = localStorage.getItem('longitude');
	// 	await axios({
	// 		'method': 'GET',
	// 		'url': 'https://node.firehrm.com/FB/flame/restaurant/getRestaurantByLocation/' + latitude + '/' + longitude + '?page=' + currentPage + "&limit=9",
	// 	})
	// 		.then((res) => {
	// 			console.log(res)
	// 			// console.log(geolocation.latitude,latitude, geolocation.longitude,longitude)
	// 			const response = res
	// 			console.log(res.data.count, 'count')
	// 			setcount(Math.ceil(res.data.count) / 9)
	// 			console.log(response, "all");
	// 			// setAPIData(resp);
	// 			setResponse(response);
	// 			// console.log(resp);
	// 			//  setvalue(resp.rating);
	// 		}).catch((err) => {
	// 			console.log("ERR =>", err);
	// 		})
	// }
	async function All() {
		await axios({
			'method': 'GET',
			'url': 'https://node.firehrm.com/FB/flame/restaurant/getRestaurantByLocation?latitude=' + latitude + '&longitude=' + longitude + '&page=' + currentPage + "&limit=9",
		})
			.then((res) => {
				console.log(res, 'pipres')
				setResponse(res.data.data)
				setcount(4)
			}).catch((err) => {
				console.log("ERR =>", err);
			})
	}
	useEffect(() => {
		async function category() {
			await axios({
				'method': 'GET',
				'url': 'https://node.firehrm.com/FB/flame/restaurant/getCategoryList?latitude=' + latitude + '&longitude=' + longitude,

			}).then((res) => {
				console.log(res,"getCategoryList")
				const resp = res.data.data
				setCategory(resp)
				console.log(resp)
			}).catch((err) => {
				console.log("ERR =>", err);
			})
		}
		category();
	}, [])
	useEffect(() => {
		async function filtersearch() {
			// e.preventDefault();
			let cuisine = category;
			if (distance == 0) {
				let distance = 7000
				var payload = { rating, distance, cuisine, latitude, longitude }
			} else {
				var payload = { rating, distance: distance * 1000, cuisine, latitude, longitude }
			}
			axios.post('https://node.firehrm.com/FB/flame/restaurant/filterFood', payload).then(res =>
			 setResponse(res.data.data)).catch((e) => console.log(e))
			// await fetch('https://node.firehrm.com/FB/flame/restaurant/filterFood',{
			// 	method: "POST",
			// 	header: {
			// 		// 'Accept': 'application/json',
			// 		'Content-Type': 'application/json',
			// 	  },
			// 	body:JSON.stringify(payload)
			// }).then(res=>console.log(res)).catch(err=>console.log(err))
		}
		if (rating != null || category != null || distance != 0) {
			filtersearch()
			console.log('false')
		} else {
			console.log('false')
		}
	}, [rating, category, distance])
	useEffect(() => {
		All();
	}, [state, currentPage])
	const handlePageClick = async (data) => {
		console.log(data.selected);
		setCurrentPage(data.selected + 1)
	}
	useEffect(() => {
		function a() {
			$(window).on("load", function () {
				var $container = $('.isotope-wrapper');
				$container.isotope({ itemSelector: '.isotope-item', layoutMode: 'masonry' });
			});
			$('.switch-field').on('click', 'input', 'change', function () {
				var selector = $(this).attr('data-filter');
				$('.isotope-wrapper').isotope({ filter: selector });
			});
		}
		a();
	}, [])

	const searchItems = (searchValue) => {
		setSearchInput(searchValue)
		if (searchInput !== '') {
			const filteredData = response.filter((item) => {
				return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
			})
			setFilteredResults(filteredData)
		}
		else {
			setFilteredResults(response)
		}
	}
	function nav(e, id) {
		navigate('/detail-restaurant-delivery', { state: id })
	}
	return (
		<div className='grid_listing'>
			<Header />
			<main className='blog-top'>
				<div className="page_header element_to_stick">
					<div className="container">
						<div className="row">
							<div className="col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
								<div className="breadcrumbs">
									<ul>
										<li><a href="#">Home</a></li>
										<li><a href="#">Category</a></li>
										<li>Page active</li>
									</ul>
								</div>
								<h1>{response.length} restaurants in Convent Street</h1>
							</div>
							<div className="col-xl-4 col-lg-5 col-md-5">
								<div className="search_bar_list">
									<input type="text" className="form-control" placeholder="Search again..." onChange={(e) => searchItems(e.target.value)} />
									<input type="submit" value="Search" />
								</div>
							</div>
						</div>
						{/* <!-- /row -->*/}
					</div>
				</div>
				{/* <!-- /page_header --> */}

				<div className="filters_full clearfix add_bottom_15">
					<div className="container">
						<div className="switch-field">
							<input type="radio" id="all" name="listing_filter" value="all" checked data-filter="*" className="selected" />
							<label for="all">All</label>
							<input type="radio" id="popular" name="listing_filter" value="popular" data-filter=".popular" />
							<label for="popular">Popular</label>
							{/* <input type="radio" id="latest" name="listing_filter" value="latest" data-filter=".latest"/>
				    <label for="latest">Latest</label> */}
						</div>
						<a className="btn_map mobile btn_filters" data-toggle="collapse" href="#collapseMap"><i className="icon_pin_alt"></i></a>
						<a href="#collapseFilters" data-toggle="collapse" className="btn_filters"><i className="icon_adjust-vert"></i><span>Filters</span></a>
					</div>
				</div>
				{/* <!-- /filters_full --> */}

				<div className="collapse" id="collapseMap">
					<div id="map" className="map"></div>
				</div>
				{/* <!-- /Map --> */}

				<div className="collapse filters_2" id="collapseFilters">
					<div className="container margin_detail">
						<div className="row">
							<div className="col-md-4">
								<div className="filter_type">
									<h6>Categories</h6>
									<ul>
										{categoryResponse.length > 0 && categoryResponse.map((x) =>
											<>
												<li>
													<label className="container_check">{x}
														<input type="checkbox" onChange={(e) => {
															console.log(category.filter((y) => y == x), "null")
															if (category.filter((y) => y == x).length != 0) {
																console.log("clear");
																setCategoryspecific(category.filter((y) => y != x))
																console.log(category, "clear")
															}
															else {
																console.log(category.indexOf((y) => y == x))
																category.push(x)
																console.log(category)
															}
														}
														} />
														<span className="checkmark"></span>
													</label>
												</li>
											</>
										)

										}

										{/* <li>
		                            <label className="container_check">Japanese - Sushi 
									<small>24</small>
		                                <input type="checkbox"/>
		                                <span className="checkmark"></span>
		                            </label>
		                        </li>
		                        <li>
		                            <label className="container_check">Burghers 
									<small>23</small>
		                                <input type="checkbox"/>
		                                <span className="checkmark"></span>
		                            </label>
		                        </li>
		                        <li>
		                            <label className="container_check">Vegetarian 
									<small>11</small>
		                                <input type="checkbox"/>
		                                <span className="checkmark"></span>
		                            </label>
		                        </li> */}
									</ul>
								</div>
							</div>
							<div className="col-md-4">
								<div className="filter_type">
									<h6>Rating</h6>
									<ul>
										<li>
											<label className="container_check">Superb 9+
												<input type="checkbox" onChange={(e) => {
													console.log(rating.filter((y) => y == "Superb"), "null")
													if (rating.filter((y) => y == "Superb").length != 0) {
														console.log("clear");
														setrating(rating.filter((y) => y != "Superb"))
														console.log(rating, "clear")
													}
													else {
														console.log(rating.indexOf((y) => y == "Superb"))
														rating.push("Superb")
														console.log(rating)
													}
												}
												} />
												<span className="checkmark"></span>
											</label>
										</li>
										<li>
											<label className="container_check">Very Good 8+
												<input type="checkbox" onChange={(e) => {
													console.log(rating.filter((y) => y == "Very Good"), "null")
													if (rating.filter((y) => y == "Very Good").length != 0) {
														console.log("clear");
														setrating(rating.filter((y) => y != "Very Good"))
														console.log(rating, "clear")
													}
													else {
														console.log(rating.indexOf((y) => y == "Very Good"))
														rating.push("Very Good")
														console.log(rating)
													}
												}}
												/>
												<span className="checkmark"></span>
											</label>
										</li>
										<li>
											<label className="container_check" >Good 7+
												<input type="checkbox" onChange={(e) => {
													console.log(rating.filter((y) => y == "Good"), "null")
													if (rating.filter((y) => y == "Good").length != 0) {
														console.log("clear");
														setrating(rating.filter((y) => y != "Good"))
														console.log(rating, "clear")
													}
													else {
														console.log(rating.indexOf((y) => y == "Good"))
														rating.push("Good")
														console.log(rating)
													}
												}
												} />
												<span className="checkmark"></span>
											</label>
										</li>
										<li>
											<label className="container_check">Pleasant 6+
												<input type="checkbox" onChange={(e) => {
													console.log(rating.filter((y) => y == "Pleasant"), "null")
													if (rating.filter((y) => y == "Pleasant").length != 0) {
														console.log("clear");
														setrating(rating.filter((y) => y != "Pleasant"))
														console.log(rating, "clear")
													}
													else {
														console.log(rating.indexOf((y) => y == "Pleasant"))
														rating.push("Pleasant")
														console.log(rating)
													}
												}
												} />
												<span className="checkmark"></span>
											</label>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-4">
								<div className="filter_type">
									<h6>Distance</h6>
									<div className="distance"> Radius around selected destination <span>{distance}</span> km</div>
									<div><input type="range" min="0" max="22" step="1" value={distance} data-orientation="horizontal" onChange={(e) => setDistance(e.target.value)} /></div>
								</div>
							</div>

						</div>
						{/* <!-- /row --> */}
					</div>
				</div>
				{/* <!-- /filters --> */}
				{/* <input type="range" className='rateRange' value="30"/> */}

				<div className="container margin_30_40">
					<div className="row isotope-wrapper">


						{/* <!-- /strip grid --> */}
						{/* {response.length > 0 && response.map((x) =>
							<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 isotope-item popular">
								<div className="strip">
									<figure>
										<img src={x.restaurantImage} className="img-fluid lazy" alt="" />
										<a href="" onClick={(e) => nav(e, x)} className="strip_info">
											<small>{x.cuisine}</small>
											<div className="item_title">
												<h3>{x.restaurantName}</h3>
												<small>{x.restaurantAddress}</small>
											</div>
										</a>
									</figure>
									<ul>
										<li><span>Avg. Price {x.averagePrice}$</span></li>
										<li>
											<div className="score"><span>{x.rating}<em>{x.review.length} Reviews</em></span><strong>{x.ratingValue}</strong></div>
										</li>
									</ul>
								</div>
							</div>
						)} */}
						{searchInput.length > 1 ?
							<>
								{filteredResults.map((x) => {
									return (<>	<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
										<div className="strip">
											<figure >
												<img src={x.restaurantImage} className="img-fluid lazy" alt="" />
												<a href="" onClick={(e) => navigate('/detail-restaurant-delivery', { state: x })} className="strip_info">
													<small>{x.cuisine}</small>
													<div className="item_title">
														<h3>{x.restaurantName}</h3>
														<small>{x.restaurantAddress}</small>
													</div>
												</a>
											</figure>
											<ul>
												<li><span>Avg. Price {x.averagePrice}$</span></li>
												<li>
													<div className="score"><span>{x.rating}<em>{x.review.length} Reviews</em></span><strong>{Math.round(x.ratingValue) * 10 / 10}</strong></div>
												</li>
											</ul>
										</div>
									</div></>)
								})}</> : <>{response.length > 0 && response.map((x) =>

									<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
										<div className="strip">
											<figure >
												<img src={x.restaurantImage} className="img-fluid lazy" alt="" />
												<a href="" onClick={(e) => navigate('/detail-restaurant-delivery', { state: x })} className="strip_info">
													<small>{x.cuisine.map((y) => <>{y}{x.cuisine[x.cuisine.length - 1] != y ? <span>, </span> : <span></span>}</>)}</small>
													<div className="item_title">
														<h3>{x.restaurantName}</h3>
														<small>{x.restaurantAddress}</small>
													</div>
												</a>
											</figure>
											<ul>
												<li><span>Avg. Price {x.averagePrice}$</span></li>
												<li>
													<div className="score"><span>{x.rating}<em>{x.review.length} Reviews</em></span><strong>{Math.round((x.ratingValue) * 10) / 10}</strong></div>
												</li>
											</ul>
										</div>
									</div>
								)}</>}
						{/* <!-- /strip grid --> */}
					</div>
					{/* <!-- /row --> */}
					{/* <div className="pagination_fg">
						<a href="#">&laquo;</a>
						<a href="#" className="active">1</a>
						<a href="#">2</a>
						<a href="#">3</a>
						<a href="#">4</a>
						<a href="#">5</a>
						<a href="#">&raquo;</a>
					</div> */}
					<div id="react-paginate" className='text-center'>
						<ReactPaginate
							previousLabel={"previous"}
							nextLabel={"next"}
							breakLabel={"..."}
							pageCount={count}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={handlePageClick}
							containerClassName={"pagination justify-content-center"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
						/>
					</div>
				</div>
				{/* <!-- /container --> */}

			</main>
			<Footer />
			<div id="toTop"></div>
			{/* <!-- Back to top button --> */}

			<div className="layer"></div>
			{/* <!-- Opacity Mask Menu Mobile --> */}

			{/* <!-- COMMON SCRIPTS --> */}
			<Helmet>
				<script src="asstes/js/common_scripts.min.js"></script>
				<script src="asstes/js/common_func.js"></script>
				<script src="asstes/assets/validate.js"></script>

				{/* // <!-- SPECIFIC SCRIPTS --> */}
				<script src="asstes/js/sticky_sidebar.min.js"></script>
				<script src="asstes/js/specific_listing.js"></script>
				<script src="asstes/js/isotope.min.js"></script>
				<script src="asstes/js/grid_listing.js"></script>
				{/* <script>

</script> */}

				{/* // <!-- Map --> */}
				<script src="asstes/js/main_map_scripts.js"></script>
				<script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBsJXlcB_yWxqnfo2fJhWzJ4TKH0Z6pTi0&callback=initMap"></script>

			</Helmet>
		</div>
	);
}

export default Grid_listing_filterscol_full_masonry;