import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Blog_post from './component/blog-post/blog_post';
import Blog from './component/blog/blog';
import Booking_delivery_2 from './component/booking_delivery_2/booking_delivery_2';
import Confirm_delivery from './component/confirm_delivery/confirm_delivery';
import Contact from './component/contact/contact';
import Grid_listing_filterscol_full_masonry from './component/grid-listing-filterscol-full-masonry/grid_listing_filterscol_full_masonry';
import Grid_listing_filterscol from './component/grid-listing-filterscol/grid_listing_filterscol';
import OTPBox from './component/Header/otp';
import Help from './component/help/help';
import Home from './component/Home/home';
import Paste from './component/Home/paste';
import Profile from './component/profile/profile';
import ResDetail from './component/resDetail/detail-restaurant-delivery';
import Component from './component/sample';
import Submit_Restaurant from './component/submit-restaurant/submit-restaurant';
import Submit_Rider from './component/submit-rider/submit_rider';
import Wishlist from './component/wishlist/wishlist';
import { Helmet } from "react-helmet";
import BlogDetails from './component/blog/blogdetails';
import Header from './component/Header/header';
import Footer from './component/Footer/footer';
import MapContainer from './component/GoogleMap/GoogleMap';
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}
function App() {
  //       <ScriptTag isHydrating={true} type="text/javascript" 
  //       src=
  // "assets/js/common_scripts.min.js" />
  useEffect(() => {
    // console.log("works");
    try {
      <Helmet>
        <script src=
          "assets/js/common_scripts.min.js"
          type="text/javascript" />
        <script src='assets/js/common_func.js' type="text/javascript" />
      </Helmet>
    }
    catch { }
  }, [])
  return (
    <div className="App">
      {/* <Header/> */}
      <Router>
        <Routes>
          <Route
            exact path="/"
            element={<Home />}
          />
          <Route
            exact path="/otp"
            element={<OTPBox />}
          />
          <Route
            exact path="/detail-restaurant-delivery"
            element={<ResDetail />}
          />
          <Route
            exact path="/help"
            element={<Help />}
          />
          <Route
            exact path="/submit_rider"
            element={<Submit_Rider />}
          />
          <Route
            exact path="/submit-restaurant"
            element={<Submit_Restaurant />}
          />
          <Route
            exact path="/wishlist"
            element={<Wishlist />}
          />
          <Route
            exact path="/contact"
            element={<Contact />}
          />
          <Route
            exact path="/confirm_delivery"
            element={<Confirm_delivery />}
          />
          <Route
            exact path="/booking_delivery_2"
            element={<Booking_delivery_2 />}
          />
          <Route
            exact path="/blog"
            element={<Blog />}
          />
          <Route
            exact path="/blog_post"
            element={<Blog_post />}
          />

          <Route
            exact path="/grid_listing_filterscol_full_masonry"
            element={<Grid_listing_filterscol_full_masonry />}
          />
          <Route
            exact path="/grid_listing_filterscol"
            element={<Grid_listing_filterscol />}
          />
          <Route
            exact path="/paste"
            element={<Paste />}
          />
          <Route
            exact path="/profile"
            element={<Profile />}
          />
          <Route
            exact path="/sample"
            element={<Component />}
          />
          <Route
            exact path="/blogdetails"
            element={<BlogDetails />}
          />
          <Route
            exact path="/googleMap"
            element={<MapContainer />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
