import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Flame_Bee8 from '../../assets/img/Flame Bee8.png'
import logo from '../../assets/img/logo.svg'
import { Helmet } from "react-helmet";
import { Button, Modal } from "react-bootstrap"
import './header.css'
import axios from 'axios';
import OTPBox from './otp';
import Sidebar from '../sidebar/sidebar';
import OTPBoxEmail from './otpemail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useEffect } from 'react';
import { AuthChild } from './../../Auth';
AuthChild.isAuthenticated()

const Header = () => {
    const token = localStorage.getItem('usertoken');
    const userName = localStorage.getItem('userName');
    const [email, setEmail] = useState();
    const [contact, setContact] = useState();
    const [name, setName] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showsign, setShowsign] = useState(false);
    const handleClosesign = (e) => { e.preventDefault(); setShowsign(false); }
    const handleShowsign = (e) => { e.preventDefault(); setShowsign(true); }


    const login = async (e) => {
        e.preventDefault();
        let payload = { email, contact }
        let resp = await fetch('https://node.firehrm.com/FB/flame/user/login',
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/JSON'

                },
                body: JSON.stringify(payload)
            })
        const statuscode = resp.status
        resp = await resp.json()
        const token = resp.token
        localStorage.setItem('token', token)
        if (statuscode === 200 || statuscode === 204 || statuscode === 201) {
            toast.success('✨ Successfully Logged In ✨', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            handleClose(e)
        }
        else if (statuscode === 400) {
            toast.success('Invalid Credentials', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if (statuscode === 500) {
            toast.success('Enter Email or Phone Number', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const register = async (e) => {
        e.preventDefault();
        let payload = { email, name, contact }
        console.log(payload)
        await fetch('https://node.firehrm.com/FB/flame/user/register',
            {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(payload)
            }).then(res => {
                if (res.status == 200 || res.status == 204 || res.status == 201) {
                    toast.success('✨ Successfully Registered ✨', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    handleClosesign(e);
                    handleShow(e)
                }
                else if (res.status == 400) {
                    toast.success('Email Already exists', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
                else if (res.status == 404) {
                    toast.success('Enter the credentials', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }

            })
    }
    useEffect(() => {
        window.onscroll = function () {
            var nav = document.getElementsByClassName('header');
            if (window.pageYOffset > 100) {
                nav.classList.add("sticky");
                console.log('scroll')
            } else {
                nav.classList.remove("sticky");
                console.log('sunscrollcroll')
            }
        }
    }, []);
    const OnClickLogin = (e) => {
        if (AuthChild.isLogged) {
            e.preventDefault();
            localStorage.removeItem('token');
            window.location.reload(false)
        }
        else {
            handleClosesign(e);  handleShow(e);
        }
    };


    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <header className="header clearfix element_to_stick">
                <div className="container">
                    <div id="logo">
                        <a href="/">
                            {/* <img src={Flame_Bee8} width="140" height="140" alt="" className="logo_normal" /> */}
                            <img src={Flame_Bee8} width="40" height="40" alt="" className="logo_normal" />
                        </a>
                    </div>
                    <div id="logo">
                        <Sidebar />
                    </div>
                    <ul id="top_menu">
                        {(token == undefined || token == null) && <li><a href="" className="login" onClick={(e) => { handleClosesign(e); handleShow(e) }}>Sign In</a></li>
                        }
                        {/* <li><a href="" className="login" >Sign In</a></li> */}
                        {/* <li><a href="" onClick={(e) => { OnClickLogin(e) }}>{AuthChild.isLogged ? "Logout" : "Get Started"}</a></li> */}
                        <li><a href="/wishlist" className="wishlist_bt_top" title="Your wishlist"></a></li>
                    </ul>
                    <a href="#0" className="open_close">
                        <i className="icon_menu"></i><span>Menu</span>
                    </a>
                    <nav className="main-menu">
                        <div id="header_menu">
                            <a href="#0" className="open_close">
                                <i className="icon_close"></i><span>Menu</span>
                            </a>
                            <a href="index.html"><img src={logo.svg} width="140" height="35" alt="" /></a>
                        </div>
                        <ul>
                            <li><a href="/">Home</a></li>
                            {/* {(token != undefined || token != null) && <li><a href="/profile" className="captalizeProfile" title="Profile ">{userName}</a></li>} */}
                        </ul>
                    </nav>
                </div>
                <Modal
                    show={show}
                    onHide={(e) => handleClose(e)}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    keyboard={false}
                    className="widthModal"
                >
                    <Modal.Header>

                        <Modal.Title id="contained-modal-title-vcenter">Sign In</Modal.Title>
                        <Button
                            onClick={(e) => handleClose(e)} className="borderBtn"
                        >
                            <i className='icon_close'></i>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="sign-in-wrapper">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" className="form-control" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>Contact</label>
                                <input type="number" className="form-control" name="email" id="email" onChange={(e) => setContact(e.target.value)} />
                            </div>
                            <input type="button" value="Login" className='continueBtn form-control' onClick={(e) => {
                                login(e)
                            }} />
                            <div className="text-center">
                                Don’t have an account? <a href="" onClick={(e) => {
                                    handleClose(e)
                                    handleShowsign(e)
                                }}>Sign up</a>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showsign}
                    onHide={(e) => handleClosesign(e)}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    keyboard={false}
                    className="widthModal"
                >
                    <Modal.Header>

                        <Modal.Title id="contained-modal-title-vcenter">Sign Up</Modal.Title>
                        <Button
                            onClick={(e) => handleClosesign(e)} className="borderBtn"
                        >
                            <i className='icon_close'></i>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="sign-in-wrapper">
                            <div className="form-group">
                                <label>User Name</label>
                                <input type="text" className="form-control" name="name" id="name" onChange={(e) => setName(e.target.value)} />

                            </div>
                            <div className="form-group">

                                <label>Email</label>
                                <input type="email" className="form-control" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />

                            </div>
                            <div className="form-group">

                                <label>Contact</label>
                                <input type="number" className="form-control" name="email" id="email" onChange={(e) => setContact(e.target.value)} />

                            </div>


                            <input type="button" value="Sign Up" className='continueBtn form-control' onClick={(e) => register(e)} />
                        </div>
                    </Modal.Body>
                </Modal>
                {/* <Modal
                    show={showotp}
                    onHide={(e) => handleCloseotp(e)}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    keyboard={false}
                    className="widthModal"
                // size='sm'
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">Sign In</Modal.Title>
                        <Button
                            onClick={(e) => handleCloseotp(e)} className="borderBtn"
                        >
                            <i className='icon_close'></i>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <OTPBox contact={contact} setContact={setContact} handleshowError={handleshowError} setError={setError} setShowotp={setShowotp} handleCloseotp={handleShowotp}/>
                    </Modal.Body>
                </Modal> */}
                {/* <Modal
                    show={showemailotp}
                    onHide={(e) => handleCloseotpemail(e)}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    keyboard={false}
                    className="widthModal"
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">Sign In</Modal.Title>
                        <Button
                            onClick={(e) => handleCloseotpemail(e)} className="borderBtn"
                        >
                            <i className='icon_close'></i>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <OTPBoxEmail email={email} setEmail={setEmail} handleshowError={handleshowError} setError={setError} setShowemailotp={setShowemailotp} handleShowemailotp={handleShowemailotp} showemailotp={showemailotp}/>
                    </Modal.Body>
                </Modal> */}
                {/* <Modal
                    show={showError}
                    onHide={(e) => handleCloseError(e)}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    keyboard={false}
                    className="widthModal"
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">Sorry Try Another</Modal.Title>
                        <Button
                            onClick={(e) => handleCloseError(e)} className="borderBtn"
                        >
                            <i className='icon_close'></i>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                       <p>{error}</p>
                       {error=="Please Check your Data"?<input type="button" value="Try another way" className='Btngreen form-control' onClick={(e) => {
                              handleCloseError(e);handleShow(e);
                            }} />:<input type="button" value="Try another way" className='Btngreen form-control' onClick={(e) => {
                                handleCloseError(e);handleShowsign(e);
                              }} />}
                            <div className="divider"><span>Or</span></div>
                            <input type="button" value="Exit" className='BtnRed form-control' onClick={(e) => {
                              handleCloseError(e)
                            }} />
                    </Modal.Body>
                </Modal> */}
            </header>
            <Helmet>
                <script src='assets/js/common_scripts.min.js' type="text/javascript" />
                <script src='assets/js/common_func.js' type="text/javascript" />
                <script src="assets/validate.js"></script>

            </Helmet>
        </>
    );
}

export default Header;