import React from 'react';
import Footer from '../Footer/footer';
import Header from '../Header/header';
import './blog.css'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import blog_1 from '../../assets/img/blog-1.jpg'
import blog_2 from '../../assets/img/blog-2.jpg'
import blog_3 from '../../assets/img/blog-3.jpg'
import blog_4 from '../../assets/img/blog-4.jpg'
import blog_5 from '../../assets/img/blog-5.jpg'
import blog_6 from '../../assets/img/blog-6.jpg'
import avatar from '../../assets/img/avatar.jpg'
import { useState, useEffect } from 'react';
const token = localStorage.getItem('usertoken');

const Blog = () => {
	const [bloglist, setBlog] = useState('');
	const navToCoinDetails = useNavigate()
	useEffect(() => {
		courseDetails();
	}, []);
	const courseDetails = async () => {
		axios({
			'method': 'GET',
			'url': 'https://node.firehrm.com/FB/flame/blog/getBlog',
			'headers': {
				'Content-Type': 'application/json',
				'Authorization': token
			},
		})
			.then((response) => {
				console.log(response.data.message, 'response')
				setBlog(response.data.message)
			})
			.then
			.catch((error) => {
				alert(error)
			}
			)
	}
	
	function blogDetails(e, x) {
		navToCoinDetails('/blogdetails', { state: x })
	  }
	return (<>
		<Header />
		<main className='blog-top'>
			<div className="page_header element_to_stick">
				<div className="container">
					<div className="row">
						<div className="col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
							<div className="breadcrumbs blog">
								<ul>
									<li><a href="#">Home</a></li>
									<li><a href="#">Category</a></li>
									<li>Page active</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-4 col-lg-5 col-md-5">
							<div className="search_bar_list">
								<input type="text" className="form-control" placeholder="Search in blog..." />
								<input type="submit" value="Search" />
							</div>
						</div>
					</div>
					{/* <!-- /row -->		        */}
				</div>
			</div>
			{/* <!-- /page_header --> */}
			<div className="container margin_30_40">
				<div className="row">
					<div className="col-lg-9">
						{/* {bloglist.map(x => */}
						{bloglist.length > 0 ?
							<div className="row">
								{bloglist.map(x =>
									<div className="col-md-6">
										<article className="blog">
											<figure>
												<a ><img src={x.blogImage} alt=""  onClick={(e) => blogDetails(e, x)} />
													<div className="preview"><span>Read more</span></div>
												</a>
											</figure>
											<div className="post_info">
												<small>{x.createdAt}</small>
												{/* <small>{moment(x.createdAt).format('DD')}</small> */}

												<h2><a href="blog-post.html">{x.userName}</a></h2>
												<p>{x.content}</p>
											</div>
										</article>
									</div>
								)}

							</div>
							: ""}

						{/* )} */}
						<div className="pagination_fg">
							<a href="#">&laquo;</a>
							<a href="#" className="active">1</a>
							<a href="#">2</a>
							<a href="#">3</a>
							<a href="#">4</a>
							<a href="#">5</a>
							<a href="#">&raquo;</a>
						</div>

					</div>

					<aside className="col-lg-3">
						<div className="widget">
							<div className="widget-title first">
								<h4>Latest Post</h4>
							</div>
							<ul className="comments-list">
								<li>
									<div className="alignleft">
										<a href="#0"><img src={blog_5} alt="" /></a>
									</div>
									<small>Category - 11.08.2016</small>
									<h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
								</li>
								<li>
									<div className="alignleft">
										<a href="#0"><img src={blog_6} alt="" /></a>
									</div>
									<small>Category - 11.08.2016</small>
									<h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
								</li>
								<li>
									<div className="alignleft">
										<a href="#0"><img src={blog_4} alt="" /></a>
									</div>
									<small>Category - 11.08.2016</small>
									<h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
								</li>
							</ul>
						</div>
						{/* <!-- /widget --> */}
						<div className="widget">
							<div className="widget-title">
								<h4>Categories</h4>
							</div>
							<ul className="cats">
								<li><a href="#">Food <span>(12)</span></a></li>
								<li><a href="#">Places to visit <span>(21)</span></a></li>
								<li><a href="#">New Places <span>(44)</span></a></li>
								<li><a href="#">Suggestions and guides <span>(31)</span></a></li>
							</ul>
						</div>
						{/* <!-- /widget --> */}
						<div className="widget">
							<div className="widget-title">
								<h4>Popular Tags</h4>
							</div>
							<div className="tags">
								<a href="#">Food</a>
								<a href="#">Bars</a>
								<a href="#">Cooktails</a>
								<a href="#">Shops</a>
								<a href="#">Best Offers</a>
								<a href="#">Transports</a>
								<a href="#">Restaurants</a>
							</div>
						</div>
						{/* <!-- /widget --> */}
					</aside>
					{/* <!-- /aside --> */}
				</div>
				{/* <!-- /row -->	 */}
			</div>
			{/* <!-- /container --> */}

		</main>
		<Footer />
		<div id="toTop"></div>
		{/* <!-- Back to top button --> */}

		<div classNameName="layer"></div>
		{/* <!-- Opacity Mask Menu Mobile --> */}
		{/* <!-- COMMON SCRIPTS --> */}
		<Helmet>
			<script src="assets/js/common_scripts.min.js"></script>
			<script src="assets/js/common_func.js"></script>
			<script src="assets/assets/validate.js"></script>
		</Helmet>
	</>);
}

export default Blog;