import React from 'react';
import Header from '../Header/header';
import icon_submit_1 from '../../assets/img/icon_submit_1.svg'
import icon_submit_2 from '../../assets/img/icon_submit_2.svg'
import icon_submit_3 from '../../assets/img/icon_submit_3.svg'
import Footer from '../Footer/footer';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios';
import { useState, useEffect } from 'react';

const Submit_Rider = () => {
	const [country, setCountry] = useState('');
	const [state, setstate] = useState('');
	const [vehicleType, setVehicleType] = useState('');
	const [aboutUs, setAboutUs] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
	const [age, setAge] = useState('');
	const [female, setFemale] = useState('');
	const [male, setMale] = useState('');
	const [faq, setfaq] = useState([]);

	useEffect(() => {
		GetFaq();
	}, []);
	async function resgisterOwner(e) {
		e.preventDefault();
		let payload = { name, country, female, age, phoneNo, email, aboutUs, vehicleType, state, male }
		console.log(payload)
		await fetch("https://node.firehrm.com/FB/flame/delivery/riderRegister",
			{
				method: "POST",
				headers: {
					'Content-type': 'application/JSON'
				},
				body: JSON.stringify(payload)
			}).then(res => {
				console.log(res)
				if (res.status == 200 || res.status == 201) {
					toast.success('Your Request Successfully sended. Please wait for Confirmation Mail', {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					// navigate('/getPackage')
				}
				else if (res.status == 400) {

					toast.warn('Already Exist, Try another Email or User Name', {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});

				}
				else if (res.status == 500) {

					toast.danger('Internal Error. Try again', {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
				else {

					toast.danger('Something went wrong. Try again', {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			}).catch(err => {
				console.log(err)

				toast.danger('Something went wrong. Try again', {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
	}
	const GetFaq = async () => {
		axios({
			'method': 'GET',
			'url': 'https://node.firehrm.com/FB/flame/management/getFAQ',
			'headers': {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				console.log(response.data.message, 'faq')
				setfaq(response.data.message)
			})
			.then
			.catch((error) => {
				alert(error)
			}
			)
	}
	return (
		<>
			<Header />
			<main>
				<ToastContainer
					position="top-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<div className="hero_single inner_pages background-image" style={{ backgroundImage: "url(../../assets/img/hero_submit_2.jpg)" }}>
					<div className="opacity-mask" style={{ background: "rgba(0, 0, 0, 0.6)" }}>
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-xl-9 col-lg-10 col-md-8">
									<h1>Become a Foogra Rider</h1>
									<p>Flexible work, competitive fees</p>
									<a href="#apply" className="btn_1 gray btn_scroll">Apply Now</a>
								</div>
							</div>
							{/* <!-- /row --> */}
						</div>
					</div>
				</div>
				{/* <!-- /hero_single --> */}

				<div className="bg_gray">
					<div className="container margin_60_40">
						<div className="main_title center">
							<span><em></em></span>
							<h2>Why Submit to Foogra</h2>
							<p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
						</div>

						<div className="row justify-content-center add_bottom_45">
							<div className="col-lg-4 col-md-6">
								<div className="box_topic">
									<figure><img src={icon_submit_1} alt="" width="110" height="100" /></figure>
									<h3>Your compensation</h3>
									<p>What you earn per order depends on your experience and ratings.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="box_topic">
									<figure><img src={icon_submit_2} alt="" width="110" height="100" /></figure>
									<h3>Choose when to work</h3>
									<p>You’ll be self-employed and free to work to your own availability.</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="box_topic">
									<figure><img src={icon_submit_3} alt="" width="110" height="100" /></figure>
									<h3>You will only need</h3>
									<p>Your vehicle (motorcycle, bike or car), an iPhone or Android device.</p>
								</div>
							</div>
						</div>
						{/* <!-- /row --> */}

						<div className="row justify-content-center">

							<div className="col-lg-8 col-md-10">
								<div className="main_title center">
									<h3 style={{ marginBottom: "0" }}>Frequent questions</h3>
									<p>Here you'll be able find answers your questions</p>
								</div>

								<div role="tablist" className="add_bottom_15 accordion_2" id="accordion_group">
									{faq?.map(x =>


										<div className="card">
											<div className="card-header" role="tab">
												<h5>
													<a data-toggle="collapse" href="#collapseOne" aria-expanded="true"><i className="indicator icon_minus-06"></i>{x.question}</a>
												</h5>
											</div>

											<div id="collapseOne" className="collapse show" role="tabpanel" data-parent="#accordion_group">
												<div className="card-body">
													<p>{x.answer}</p>
												</div>
											</div>
										</div>
									)}

								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container margin_60_40" id="apply">

					<div className="row justify-content-center">
						<div className="col-lg-6 col-md-8">
							<div className="main_title center">
								<span><em></em></span>
								<h2>Apply Now</h2>
								<p>Note that you must be over 18 years old</p>
							</div>

							<div id="wizard_container">
								<div id="top-wizard">
									<div id="progressbar"></div>
								</div>
								{/* <!-- /top-wizard --> */}
								<form onSubmit={(e) => resgisterOwner(e)}>
									<input id="website" name="website" type="text" value="" />
									{/* <!-- Leave for security protection, read docs for details --> */}
									<div id="middle-wizard">

										<div className="step">
											<h3 className="main_question"><strong>1/5</strong>Where would you like to work?</h3>
											<div className="form-group">
												<div className="custom_select clearfix">
													<select className="wide required form-control" name="location" onChange={(e) => setCountry(e.target.value)}>
														<option value="">Your Country</option>
														<option value="London">London</option>
														<option value="Paris">Paris</option>
														<option value="Madrid">Madrid</option>
														<option value="Berlin">Berlin</option>
														<option value="Rome">Rome</option>
													</select>
												</div>
												
											</div>
										</div>
										<div className="step">
											<h3 className="main_question"><strong>2/5</strong>Where would you like to work?</h3>
											<div className="form-group">
												<div className="custom_select clearfix">
													<select className="wide required form-control" name="location" onChange={(e) => setstate(e.target.value)}>
														<option value="">Your State</option>
														<option value="London">Tanilnadu</option>
														<option value="Paris">Padicherry</option>
														<option value="Madrid">andra</option>
														<option value="Berlin">kerala</option>
														<option value="Rome">Rome</option>
													</select>
												</div>
											</div>
										</div>
										<div className="step">
											<h3 className="main_question"><strong>3/5</strong>What's your vehicle type?</h3>
											<div className="form-group">
												<div className="custom_select clearfix">
													<select className="wide required form-control" name="vehicle" onChange={(e) => setVehicleType(e.target.value)}>
														<option value="">Your Vehicle</option>
														<option value="London">Scooter</option>
														<option value="Paris">Bicycle</option>
														<option value="Madrid">Car</option>

													</select>
												</div>
											</div>
										</div>
										<div className="step">
											<h3 className="main_question"><strong>4/5</strong>How did you hear about us?</h3>
											<div className="form-group">
												<div className="custom_select clearfix">
													<select className="wide required form-control" name="vehicle" onChange={(e) => setAboutUs(e.target.value)}>
														<option value="">Google Search Engine</option>
														<option value="London">A friend of mine</option>
														<option value="Paris">Print Advertise</option>
														<option value="Madrid">Newspaper</option>
														<option value="Madrid">Other</option>
													</select>
												</div>
											</div>
										</div>

										<div className="submit step">
											<h3 className="main_question"><strong>5/5</strong>Tell us about yourself</h3>
											<div className="form-group">
												<input type="text" name="firstname" className="form-control required" placeholder="First and Last Name" onChange={(e) => setName(e.target.value)} />
											</div>
											<div className="form-group">
												<input type="email" name="email" className="form-control required" placeholder="Your Email" onChange={(e) => setEmail(e.target.value)} />
											</div>
											<div className="form-group">
												<input type="text" name="telephone" className="form-control required" placeholder="Your Telephone" onChange={(e) => setPhoneNo(e.target.value)} />
											</div>
											<div className="row">
												<div className="col-3">
													<div className="form-group">
														<input type="text" name="age" className="form-control required" placeholder="Age" onChange={(e) => setAge(e.target.value)} />
													</div>
												</div>
												<div className="col-9">
													<div className="form-group radio_input">
														<label className="container_radio">Male
															<input type="radio" name="gender" value="Female" className="required" onChange={(e) => setFemale(e.target.value)} />
															<span className="checkmark"></span>
														</label>
														<label className="container_radio">Female
															<input type="radio" name="gender" value="male" className="required" onChange={(e) => setMale(e.target.value)} />
															<span className="checkmark"></span>
														</label>
													</div>
												</div>
											</div>
											{/* <!-- /row --> */}
										</div>
										{/* <!-- /step--> */}
									</div>
									{/* <!-- /middle-wizard --> */}
									<div id="bottom-wizard">
										<button type="submit" name="process" className="submit">Submit</button>
									</div>
									{/* <!-- /bottom-wizard --> */}
								</form>
							</div>
							{/* <!-- /Wizard container --> */}
						</div>
						{/* <!-- /col --> */}
					</div>
					{/* <!-- /row -->	 */}
				</div>
				{/* <!-- /container --> */}

			</main>
			<Footer />

			<div id="toTop"></div>
			{/* <!-- Back to top button --> */}
			<div className="layer"></div>
			{/* <!-- Opacity Mask Menu Mobile --> */}
			<div className="modal fade" id="terms-txt" tabindex="-1" role="dialog" aria-labelledby="termsLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title" id="termsLabel">Terms and conditions</h4>
							<button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
						</div>
						<div className="modal-body">
							<p>Lorem ipsum dolor sit amet, in porro albucius qui, in <strong>nec quod novum accumsan</strong>, mei ludus tamquam dolores id. No sit debitis meliore postulant, per ex prompta alterum sanctus, pro ne quod dicunt sensibus.</p>
							<p>Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod novum accumsan, mei ludus tamquam dolores id. No sit debitis meliore postulant, per ex prompta alterum sanctus, pro ne quod dicunt sensibus. Lorem ipsum dolor sit amet, <strong>in porro albucius qui</strong>, in nec quod novum accumsan, mei ludus tamquam dolores id. No sit debitis meliore postulant, per ex prompta alterum sanctus, pro ne quod dicunt sensibus.</p>
							<p>Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod novum accumsan, mei ludus tamquam dolores id. No sit debitis meliore postulant, per ex prompta alterum sanctus, pro ne quod dicunt sensibus.</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn_1" data-dismiss="modal">Close</button>
						</div>
					</div>
					{/* <!-- /.modal-content --> */}
				</div>
				{/* <!-- /.modal-dialog --> */}

			</div>
			<Helmet>
				<script src="asstes/js/common_scripts.min.js"></script>
				<script src="asstes/js/common_func.js"></script>
				<script src="asstes/assets/validate.js"></script>
				<script src="asstes/js/wizard/wizard_scripts.js"></script>
				<script src="asstes/js/wizard/wizard_func.js"></script>
			</Helmet>
		</>
	);
}



export default Submit_Rider;