import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import './grid-listing_filterscol.css'
import { Helmet } from 'react-helmet';
import Header from '../Header/header';
import Footer from '../Footer/footer';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';
const Grid_listing_filterscol = () => {

	const { state } = useLocation();
	const [filteredResults, setFilteredResults] = useState([]);
	const [checked, setChecked] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const navigate = useNavigate();
	const latitude = localStorage.getItem('latitude');
	const longitude = localStorage.getItem('longitude');
	console.log(latitude,'lat')
	console.log(state, "state")
	const status = state
	const [response, setResponse] = useState([])
	const [categoryResponse, setCategory] = useState([])
	const [category, setCategoryspecific] = useState([])
	const [rating, setrating] = useState([])
	const [distance, setDistance] = useState(0)
	const [price1, setprice] = useState([]);
	const [count, setcount] = useState(2)
	const [currentPage, setCurrentPage] = useState(1)
	useEffect(() => {
		async function category() {
			await axios({
				'method': 'GET',
				'url': 'https://node.firehrm.com/FB/flame/restaurant/getCategoryList?latitude=' + latitude + '&longitude=' + longitude,
			}).then((res) => {
				console.log(res,"category")
				const resp = res.data.data
				setCategory(resp)
			}).catch((err) => {
				console.log("ERR =>", err);
			})
		}
		category();
	}, [])

	async function filtersearch(e) {
		e.preventDefault();
		let price = Math.max(...price1)
		let cuisine = category;
		if (distance == 0) {
			let distance = 7000
			var payload = { rating, price, distance, cuisine, latitude, longitude }
		} else {
			var payload = { rating, price, distance: distance * 1000, cuisine, latitude, longitude }
		}
		console.log(typeof (payload))
		var k = JSON.stringify(payload)
		console.log(typeof (JSON.parse(k)))
		axios.post('https://node.firehrm.com/FB/flame/restaurant/filterFood', payload).then(res =>
			{   console.log(res,"filterFood")
				setResponse(res.data.data)})
			.catch((e) => console.log(e))
	}
	// async function popularRes() {
	// 	await axios({
	// 		'method': 'GET',
	// 		'url': 'https://node.firehrm.com/FB/flame/restaurant/getRestaurantLocationByRating?latitude=' + latitude + '&longitude=' + longitude + '&page=' + currentPage + "&limit=9",
	// 	})
	// 		.then((res) => {
	// 			const response = res.data.message[0].data
	// 			setResponse(res.data.message[0].data)
	// 			setcount(4)
	// 		}).catch((err) => {
	// 			console.log("ERR =>", err);
	// 		})
	// }
	async function popularRes() {
		await axios({
			'method': 'GET',
			'url': 'https://node.firehrm.com/FB/flame/restaurant/getRestaurantByLocation?latitude=' + latitude + '&longitude=' + longitude + '&page=' + currentPage + "&limit=9",
		})
			.then((res) => {
				console.log(res, 'pipres')
				setResponse(res.data.data)
				setcount(4)
			}).catch((err) => {
				console.log("ERR =>", err);
			})
	}


	async function offerRes() {
		await axios({
			'method': 'GET',
			'url': 'https://node.firehrm.com/FB/flame/restaurant/getRestaurantLocationByOffer?latitude=' + latitude + '&longitude=' + longitude + '&page=' + currentPage + "&limit=9",
		})
			.then((res) => {
				console.log(res,'offer')
				const response = res.data.data
				setResponse(response)

				setcount(4)
				console.log(res);
			}).catch((err) => {
				console.log("ERR =>", err);
			})
	}

	const handlePageClick = async (data) => {
		console.log(data.selected);
		setCurrentPage(data.selected + 1)
	}
	
	async function searchFood(value) {
		console.log(latitude, longitude)
		await axios({
			'method': 'GET',
			'url': 'https://node.firehrm.com/FB/flame/restaurant/searchAPI/' + value + '?latitude=' + latitude + '&longitude=' + longitude,
		})
			.then((res) => {
				console.log(res.data.data[0].data,"120")
				const resp = res.data.data[0].data
				// console.log(resp, "offers");
				setResponse(resp)
			}).catch((err) => {
				console.log("ERR =>", err);
			})
	}

	useEffect(() => {
		switch (status) {
			case "popularRes":
				popularRes()
				break;
			case "offerRes":
				offerRes()
				break;
			default:
				break;
		}
		if (state.type == "search") {
			searchFood(state.search)
		}
	}, [status, currentPage])


	const searchItems = (searchValue) => {
		setSearchInput(searchValue)
		if (searchInput !== '') {
			const filteredData = response.filter((item) => {
				console.log("151",item)
				return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
			})
			console.log(filteredData,'filter')
			setFilteredResults(filteredData)
		}
		else {
			setFilteredResults(response)
		}
	}
	return (
		<>
			<Header />
			<main className='blog-top'>
				<div className="page_header element_to_stick">
					<div className="container">
						<div className="row">
							<div className="col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
								<div className="breadcrumbs">
									<ul>
										<li><a href="#">Home</a></li>
										<li><a href="#">Category</a></li>
										<li>Page active</li>
									</ul>
								</div>
								<h1>{response.length} restaurants in Convent Street 2983</h1>
							</div>
							<div className="col-xl-4 col-lg-5 col-md-5">
								<div className="search_bar_list">
									<input type="text" className="form-control" placeholder="Search again..." onChange={(e) => searchItems(e.target.value)} />
									<input type="submit" value="Search" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container margin_30_40">
					<div className="row">
						<aside className="col-lg-3" id="sidebar_fixed">
							<div className="clearfix">
								<div className="sort_select">
									<select name="sort" id="sort">
										<option value="popularity" defaultValue="selected">Sort by Popularity</option>
										<option value="rating">Sort by Average rating</option>
										<option value="date">Sort by newness</option>
										<option value="price">Sort by Price: low to high</option>
										<option value="price-desc">Sort by Price: high to low</option>
									</select>
								</div>
								<a href="#0" className="open_filters btn_filters"><i className="icon_adjust-vert"></i><span>Filters</span></a>
							</div>
							<div className="filter_col">
								<div className="inner_bt"><a href="#" className="open_filters"><i className="icon_close"></i></a></div>
								<div className="filter_type">
									<h4><a href="#filter_1" data-toggle="collapse" className="opened">Categories</a></h4>
									<div className="collapse show" id="filter_1">
										<ul>
											{categoryResponse.length > 0 && categoryResponse.map((x) =>
												<>
													<li>
														<label className="container_check">{x}
															<input type="checkbox" onChange={(e) => {
																console.log(category.filter((y) => y == x), "null")
																if (category.filter((y) => y == x).length != 0) {
																	console.log("clear");
																	setCategoryspecific(category.filter((y) => y != x))
																	console.log(category, "clear")
																}
																else {
																	console.log(category.indexOf((y) => y == x))
																	category.push(x)
																	console.log(category)
																}
															}
															} />
															<span className="checkmark"></span>
														</label>
													</li>
												</>
											)
											}
										</ul>
									</div>
								</div>
								<div className="filter_type">
									<h4><a href="#filter_2" data-toggle="collapse" className="closed">Rating</a></h4>
									<div className="collapse" id="filter_2">
										<ul>
											<li>
												<label className="container_check">Superb 9+
													<input type="checkbox" onChange={(e) => {
														console.log(rating.filter((y) => y == "Superb"), "null")
														if (rating.filter((y) => y == "Superb").length != 0) {
															console.log("clear");
															setrating(rating.filter((y) => y != "Superb"))
															console.log(rating, "clear")
														}
														else {
															console.log(rating.indexOf((y) => y == "Superb"))
															rating.push("Superb")
															console.log(rating)
														}
													}
													} />
													<span className="checkmark"></span>
												</label>
											</li>
											<li>
												<label className="container_check">Very Good 8+
													<input type="checkbox" onChange={(e) => {
														console.log(rating.filter((y) => y == "Very Good"), "null")
														if (rating.filter((y) => y == "Very Good").length != 0) {
															console.log("clear");
															setrating(rating.filter((y) => y != "Very Good"))
															console.log(rating, "clear")
														}
														else {
															console.log(rating.indexOf((y) => y == "Very Good"))
															rating.push("Very Good")
															console.log(rating)
														}
													}}
													/>
													<span className="checkmark"></span>
												</label>
											</li>
											<li>
												<label className="container_check" >Good 7+
													<input type="checkbox" onChange={(e) => {
														console.log(rating.filter((y) => y == "Good"), "null")
														if (rating.filter((y) => y == "Good").length != 0) {
															console.log("clear");
															setrating(rating.filter((y) => y != "Good"))
															console.log(rating, "clear")
														}
														else {
															console.log(rating.indexOf((y) => y == "Good"))
															rating.push("Good")
															console.log(rating)
														}
													}
													} />
													<span className="checkmark"></span>
												</label>
											</li>
											<li>
												<label className="container_check">Pleasant 6+
													<input type="checkbox" onChange={(e) => {
														console.log(rating.filter((y) => y == "Pleasant"), "null")
														if (rating.filter((y) => y == "Pleasant").length != 0) {
															console.log("clear");
															setrating(rating.filter((y) => y != "Pleasant"))
															console.log(rating, "clear")
														}
														else {
															console.log(rating.indexOf((y) => y == "Pleasant"))
															rating.push("Pleasant")
															console.log(rating)
														}
													}
													} />
													<span className="checkmark"></span>
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="filter_type">
									<h4><a href="#filter_3" data-toggle="collapse" className="closed">Distance</a></h4>
									<div className="collapse" id="filter_3">
										<div className="distance"> Radius around selected destination <span>{distance}</span> km</div>
										<div className="add_bottom_15"><input type="range" min="0" max="22" step="1" value={distance} data-orientation="horizontal" onChange={(e) => setDistance(e.target.value)} /></div>
									</div>
								</div>
								<div className="filter_type">
									<h4><a href="#filter_4" data-toggle="collapse" className="closed">Price</a></h4>
									<div className="collapse" id="filter_4">
										<ul>
											<li>
												<label className="container_check">Above 100
													<input type="checkbox" onChange={(e) => {
														console.log(price1.filter((y) => y == 100), "null")
														if (price1.filter((y) => y == 100).length != 0) {
															console.log("clear");
															setprice(price1.filter((y) => y != 100))
															console.log(price1, "clear")
														}
														else {
															console.log(price1.indexOf((y) => y == 100))
															price1.push(100)
															console.log(price1)
														}
													}}
													/>
													<span className="checkmark"></span>
												</label>
											</li>
											<li>
												<label className="container_check">Above 250
													<input type="checkbox" onChange={(e) => {
														console.log(price1.filter((y) => y == 250), "null")
														if (price1.filter((y) => y == 250).length != 0) {
															console.log("clear");
															setprice(price1.filter((y) => y != 250))
															console.log(price1, "clear")
														}
														else {
															console.log(price1.indexOf((y) => y == 250))
															price1.push(250)
															console.log(price1)
														}
													}}
													/>
													<span className="checkmark"></span>
												</label>
											</li>
											<li>
												<label className="container_check">Above 500
													<input type="checkbox" onChange={(e) => {
														console.log(price1.filter((y) => y == 500), "null")
														if (price1.filter((y) => y == 500).length != 0) {
															console.log("clear");
															setprice(price1.filter((y) => y != 500))
															console.log(price1, "clear")
														}
														else {
															console.log(price1.indexOf((y) => y == 500))
															price1.push(500)
															console.log(price1)
														}
													}}
													/>
													<span className="checkmark"></span>
												</label>
											</li>
											<li>
												<label className="container_check">Above 1000
													<input type="checkbox" onChange={(e) => {
														console.log(price1.filter((y) => y == 1000), "null")
														if (price1.filter((y) => y == 1000).length != 0) {
															console.log("clear");
															setprice(price1.filter((y) => y != 1000))
															console.log(price1, "clear")
														}
														else {
															console.log(price1.indexOf((y) => y == 1000))
															price1.push(1000)
															console.log(price1)
														}
													}}
													/>
													<span className="checkmark"></span>
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="buttons">
									<a href="" className="btn_1 full-width" onClick={(e) => filtersearch(e)}>Filter</a>
								</div>
							</div>
						</aside>
						<div className="col-lg-9">
							<div className="row">
								{searchInput.length > 1 ?
									<>
										{filteredResults.map((x) => {
											return (<>

												<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
													<div className="strip">
														<figure >
															<img src={x.restaurantImage} className="img-fluid lazy" alt="" />
															<a href="" onClick={(e) => navigate('/detail-restaurant-delivery', { state: x })} className="strip_info">
																<small>{x.cuisine}</small>
																<div className="item_title">
																	<h3>{x.restaurantName}</h3>
																	<small>{x.restaurantAddress}</small>
																</div>
															</a>
														</figure>
														<ul>
															<li><span>Avg. Price {x.averagePrice}$</span></li>
															<li>
																<div className="score"><span>{x.rating}<em>{x.review.length} Reviews</em></span><strong>{Math.round(x.ratingValue) * 10 / 10}</strong></div>
															</li>
														</ul>
													</div>
												</div></>)
										})}</> : <>
										{response.length > 0 && response.map((x) =>
											<div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
												<div className="strip">
													<figure >
														<img src={x.restaurantImage} className="img-fluid lazy" alt="" />
														<a href="" onClick={(e) => navigate('/detail-restaurant-delivery', { state: x })} className="strip_info">
															<small>{x.cuisine.map((y) => <>{y}{x.cuisine[x.cuisine.length - 1] != y ? <span>, </span> : <span></span>}</>)}</small>
															<div className="item_title">
																<h3>{x.restaurantName}</h3>
																<small>{x.restaurantAddress}</small>
															</div>
														</a>
													</figure>
													<ul>
														<li><span>Avg. Price {x.averagePrice}$</span></li>
														<li>
															<div className="score"><span>{x.rating}<em>{x.review.length} Reviews</em></span><strong>{Math.round((x.ratingValue) * 10) / 10}</strong></div>
														</li>
													</ul>
												</div>
											</div>
										)}</>}
							</div>
							<div id="react-paginate" className='text-center'>
								<ReactPaginate
									previousLabel={"previous"}
									nextLabel={"next"}
									breakLabel={"..."}
									pageCount={count}
									marginPagesDisplayed={2}
									pageRangeDisplayed={3}
									onPageChange={handlePageClick}
									containerClassName={"pagination justify-content-center"}
									pageClassName={"page-item"}
									pageLinkClassName={"page-link"}
									previousClassName={"page-item"}
									previousLinkClassName={"page-link"}
									nextClassName={"page-item"}
									nextLinkClassName={"page-link"}
									breakClassName={"page-item"}
									breakLinkClassName={"page-link"}
									activeClassName={"active"}
								/>
							</div>
						</div>
					</div>
				</div>
			</main>
			<Footer />
			<div id="toTop"></div>
			<div className="layer"></div>
			<Helmet>
				<script src="asstes/js/common_scripts.min.js"></script>
				<script src="asstes/js/common_func.js"></script>
				<script src="asstes/assets/validate.js"></script>
				<script src="asstes/js/sticky_sidebar.min.js"></script>
				<script src="asstes/js/specific_listing.js"></script>
			</Helmet>
		</>);
}

export default Grid_listing_filterscol;