import React from 'react';
import Autocomplete from "react-google-autocomplete";

const Component = () => (
  <div>
    {/* <GooglePlacesAutocomplete
      apiKey="AizaSyBs1VUk_RdHZxyexV_U18_wuaH_aEAU4gA"
    /> */}
    <Autocomplete
  apiKey="AIzaSyBsJXlcB_yWxqnfo2fJhWzJ4TKH0Z6pTi0"
  onPlaceSelected={(place) => {
    console.log(place);
  }}
/>
  </div>
);

export default Component;