import React, { useState, useEffect } from 'react';
import Footer from '../Footer/footer';
import Header from '../Header/header';
import icon_ccv from '../../assets/img/icon_ccv.gif'
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';

const Booking_delivery_2 = () => {
	const { state } = useLocation();
	// const [amount, setamount] = useState('');
	const nav = useNavigate()
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [contact, setPhone] = useState('');
	const [address, setAddress] = useState('');
	const [data, setData] = useState('');


	const amount = state.toString();
	const parse = parseInt(amount);
	console.log(parse, 'parse')
	console.log(amount, 'amot')
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://checkout.razorpay.com/v1/Booking_delivery_2.js';
		script.async = true;
		document.body.appendChild(script);
		console.log(state, 'state')
	}, []);
	var options = {
		"key": "rzp_test_vcN8VSePA0MiTt", // Enter the Key ID generated from the Dashboard
		"amount": amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
		"currency": "INR",
		"name": "Acme Corp",
		"description": "Test Transaction",
		"image": "https://example.com/your_logo",
		"order_id": data.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
		"handler": function (response) {
			// alert(response.razorpay_payment_id);
			// alert(response.razorpay_order_id);
			// alert(response.razorpay_signature);
			nav('/confirm_delivery')
		}, "prefill": {
			"name": data.name,
			"email": data.email,
			"contact": data.contact
		},
		"notes": {
			"address": data.address
		},
		"theme": {
			"color": "#3399cc"
		}
	};
	// var rzp1 = new Razorpay(options);
	let rzp1 = new window.Razorpay(options);
	rzp1.on('payment.success', function (response) {
		// alert(response.error.code);
		// alert(response.error.description);
		// alert(response.error.source);
		// alert(response.error.step);
		// alert(response.error.reason);
		// alert(response.error.metadata.order_id);
		// alert(response.error.metadata.payment_id);
		nav('/confirm_delivery')
	});

	async function RazorPayIntegration(e) {
		rzp1.open();
		e.preventDefault();
		// nav('/confirm_delivery')

	}
	async function SubmitDetails(e) {
		e.preventDefault();
		const token = localStorage.getItem('token');
		// debugger
		let payload = { name, email, contact, address, amount }
		let result = await fetch('https://node.firehrm.com/FB/flame/payment/createOrderId',
			{
				method: "POST",
				headers: {
					'Authorization': token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(payload)
			})
		const res = await result.json()
		console.log(89,result)
		setData(res.message)
		console.log(data, 'data')
		if (result.status == 200) {
			RazorPayIntegration(e);
		}
	}
	//  setamount(state)
	// const setTotalAmount=()=>{
	// 	setamount(state)
	// }
	return (

		<>
			<Header />
			<main className="bg_gray pattern" style={{ marginTop: '155px' }}>
				<div className="container margin_60_40">
					<div className="row justify-content-center" style={{ marginTop: "80px" }}>
						<div className="col-xl-6 col-lg-8">
							<div className="box_booking_2 style_2">
								<div className="head">
									<div className="title">
										<h3>Personal Details</h3>
									</div>
								</div>
								{/* <!-- /head --> */}
								<div className="main">

									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label> Name</label>
												<input className="form-control" placeholder="Enter Name" onChange={(e) => setName(e.target.value)} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Email Address</label>
												<input className="form-control" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Phone</label>
												<input className="form-control" placeholder="Phone" onChange={(e) => setPhone(e.target.value)} />
											</div>
										</div>
										{/* <div className="col-md-6">
											<div className="form-group">
												<label>Total Amount</label>
												<input className="form-control" placeholder="Enter Total amount" value={state} onChange={(e) => setamount(e.target.value)} />
											</div>
										</div> */}
									</div>
									<div className="form-group">
										<label>Full Address</label>
										<input className="form-control" placeholder="Full Address" onChange={(e) => setAddress(e.target.value)} />
									</div>
									<a className="btn_1 full-width mb_5" onClick={(e) => SubmitDetails(e)}>Order Now</a>


								</div>
							</div>
							{/* <div className="box_booking_2 style_2">
								<div className="head">
									<div className="title">
										<h3>Payment Method</h3>
									</div>
								</div>
								<div className="main">
									<div className="payment_select">
										<label className="container_radio">Credit Card
											<input type="radio" value="" checked name="payment_method" />
											<span className="checkmark"></span>
										</label>
										<i className="icon_creditcard"></i>
									</div>
									<div className="form-group">
										<label>Name on card</label>
										<input type="text" className="form-control" id="name_card_order" name="name_card_order" placeholder="First and last name" />
									</div>
									<div className="form-group">
										<label>Card number</label>
										<input type="text" id="card_number" name="card_number" className="form-control" placeholder="Card number" />
									</div>
									<div className="row">
										<div className="col-md-6">
											<label>Expiration date</label>
											<div className="row">
												<div className="col-md-6 col-6">
													<div className="form-group">
														<input type="text" id="expire_month" name="expire_month" className="form-control" placeholder="mm" />
													</div>
												</div>
												<div className="col-md-6 col-6">
													<div className="form-group">
														<input type="text" id="expire_year" name="expire_year" className="form-control" placeholder="yyyy" />
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label>Security code</label>
												<div className="row">
													<div className="col-md-4 col-6">
														<div className="form-group">
															<input type="text" id="ccv" name="ccv" className="form-control" placeholder="CCV" />
														</div>
													</div>
													<div className="col-md-8 col-6">
														<img src={icon_ccv} width="50" height="29" alt="ccv" /><small>Last 3 digits</small>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="payment_select" id="paypal">
										<label className="container_radio">Pay with Paypal
											<input type="radio" value="" name="payment_method" />
											<span className="checkmark"></span>
										</label>
									</div>
									<div className="payment_select">
										<label className="container_radio">Pay with Cash
											<input type="radio" value="" name="payment_method" />
											<span className="checkmark"></span>
										</label>
										<i className="icon_wallet"></i>
									</div>
								</div>
							</div> */}
						</div>
						{/* <div className="col-xl-4 col-lg-4" id="sidebar_fixed">
							<div className="box_booking">
								<div className="head">
									<h3>Order Summary</h3>
									<div>Pizzeria da Alfredo</div>
								</div>
								<div className="main">
									<ul>
										<li>Date<span>Today 23/03/2022</span></li>
										<li>Hour<span>08.30pm</span></li>
										<li>Type<span>Delivery</span></li>
									</ul>
									<hr />
									<ul className="clearfix">
										<li><a href="#0">1x Enchiladas</a><span>$11</span></li>
										<li><a href="#0">2x Burrito</a><span>$14</span></li>
										<li><a href="#0">1x Chicken</a><span>$18</span></li>
										<li><a href="#0">2x Corona Beer</a><span>$9</span></li>
										<li><a href="#0">2x Cheese Cake</a><span>$11</span></li>
									</ul>
									<ul className="clearfix">
										<li>Subtotal<span>$56</span></li>
										<li>Delivery fee<span>$10</span></li>
										<li className="total">Total<span>$66</span></li>
									</ul>
									<a href="/confirm_delivery" className="btn_1 full-width mb_5">Order Now</a>
									<div className="text-center"><small>Or Call Us at <strong>0452 2525256</strong></small></div>
								</div>
							</div>
						</div> */}

					</div>
				</div>
			</main>
			<Footer />
			<div id="toTop"></div>

			<div classNameNameName="layer"></div>
			<Helmet>
				<script src="assets/js/common_scripts.min.js"></script>
				<script src="assets/js/common_func.js"></script>
				<script src="assets/assets/validate.js"></script>
				<script src="assets/js/sticky_sidebar.min.js"></script>
				<script src="assets/js/specific_detail.js"></script>
				<script src="assets/js/booking_delivery.js"></script>

			</Helmet>

		</>
	);
}

export default Booking_delivery_2;