import React from 'react';
import facebook_icon from '../../assets/img/facebook_icon.svg'
import instagram_icon from '../../assets/img/instagram_icon.svg'
import card_all from '../../assets/img/cards_all.svg'
import twitter_icon from '../../assets/img/twitter_icon.svg'
import video_fix from '../../assets/img/video_fix.png'
import youtube_icon from '../../assets/img/youtube_icon.svg'
import { useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
const Footer = () => {
    const navigate=useNavigate()
    return (<>
     <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <h3 data-target="#collapse_1">Quick Links</h3>
                            <div className="collapse dont-collapse-sm links" id="collapse_1">
                                <ul>
                                    <li><a href="/submit_rider">Become a Rider</a></li>
                                    <li><a href="submit-restaurant">Add your restaurant</a></li>
                                    <li><a href="/help">Help</a></li>
                                    <li><a href="/blog">Blog</a></li>
                                    <li><a href="/contact">Contacts</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h3 data-target="#collapse_2">Categories</h3>
                            <div className="collapse dont-collapse-sm links" id="collapse_2">
                                <ul>
                                    <li><a href="/grid_listing_filterscol_full_masonry">Top Categories</a></li>
                                    <li><a href="" onClick={(e)=>navigate('/grid_listing_filterscol',
                                    {state:"popularRes"})
                                    }>Best Rated</a></li>
                                    <li><a href="" onClick={(e)=>navigate('/grid_listing_filterscol',{state:"offerRes"})}>Best Price</a></li>
                                    {/* <li><a href="" onClick={(e)=>navigate('/grid_listing_filtercol',{state:"popularRes"})}>Latest Submissions</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h3 data-target="#collapse_3">Contacts</h3>
                            <div className="collapse dont-collapse-sm contacts" id="collapse_3">
                                <ul>
                                    <li><i className="icon_house_alt"></i>No-61, Varsabari Complex, 2nd Floor Ponmeni,Main Road, Bypass Rd, Madurai, 625016, Tamil Nadu, India</li>
                                    <li><i className="icon_mobile"></i>+91 80726 09370</li>
                                    <li><i className="icon_mail_alt"></i><a href="#0">hello@firebeetechnoservices.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h3 data-target="#collapse_4">Keep in touch</h3>
                            <div className="collapse dont-collapse-sm" id="collapse_4">
                                <div id="newsletter">
                                    <div id="message-newsletter"></div>
                                    <form method="post" action="assets/newsletter.php" name="newsletter_form" id="newsletter_form">
                                        <div className="form-group">
                                            <input type="email" name="email_newsletter" id="email_newsletter" className="form-control" placeholder="Your email" />
                                            <button type="submit" id="submit-newsletter"><i className="arrow_carrot-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                                <div className="follow_us">
                                    <h5>Follow Us</h5>
                                    <ul>
                                        <li><a href="#0"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src={twitter_icon.svg} alt="" className="lazy" /></a></li>
                                        <li><a href="#0"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src={facebook_icon.svg} alt="" className="lazy" /></a></li>
                                        <li><a href="#0"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src={instagram_icon.svg} alt="" className="lazy" /></a></li>
                                        <li><a href="#0"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src={youtube_icon.svg} alt="" className="lazy" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /row--> */}
                    <hr />
                    <div className="row add_bottom_25">
                        <div className="col-lg-6">
                            <ul className="footer-selector clearfix">
                                <li>
                                    <div className="styled-select lang-selector">
                                        <select>
                                            <option value="English" selected>English</option>
                                            <option value="French">French</option>
                                            <option value="Spanish">Spanish</option>
                                            <option value="Russian">Russian</option>
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div className="styled-select currency-selector">
                                        <select>
                                            <option value="INR" selected>INR</option>
                                            <option value="Dollar">Dollar</option>
                                        </select>
                                    </div>
                                </li>
                                <li><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src={card_all.svg} alt="" width="198" height="30" className="lazy" /></li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <ul className="additional_links">
                                <li><a href="#0">Terms and conditions</a></li>
                                <li><a href="#0">Privacy</a></li>
                                <li><span>© Flamebee</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <Helmet>
                <script src='assets/js/common_scripts.min.js' type="text/javascript" />
                <script src='assets/js/common_func.js' type="text/javascript" />
            </Helmet> */}
            </footer>
    </>);
}


export default Footer;