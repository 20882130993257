import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import about_1 from '../../assets/img/about_1.svg'
import about_2 from '../../assets/img/about_2.svg'
import about_3 from '../../assets/img/about_3.svg'
import arrow_about from '../../assets/img/arrow_about.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Footer from '../Footer/footer';
import Header from '../Header/header';
import { Helmet } from 'react-helmet';
import axios from 'axios';
const Submit_Restaurant = () => {
	const navigate = useNavigate();
	const [item, setItems] = useState([]);
	const [selectElement, setSelectElement] = useState(0);
	const [name, setName] = useState("");
	const [userName, setUserName] = useState("");
	const [email, seteamil] = useState("");
	const [password, setpassword] = useState("");
	const [confirmPassword, setconfirmPassword] = useState("");
	const [bankName, setbankName] = useState("");
	const [accountNumber, setaccountNumber] = useState("");
	const [ifscCode, setifscCode] = useState("");
	const [panNumber, setpanNumber] = useState("");
	const [restaurantName, setrestaurantName] = useState("");
	const [restaurantCity, setrestaurantCity] = useState("");
	const [restaurantAddress, setrestaurantAddress] = useState("");
	const [restaurantCountry, setrestaurantCountry] = useState("");
	const token = localStorage.getItem('usertoken')

	const handleClick = (id) => {
		setSelectElement(id)
	}
	async function allpackage() {
		const data = await axios({
			'method': 'GET',
			'url': `https://node.firehrm.com/FB/flame/superAdmin/getPackagePlan`,
			'headers': {
				'Content-Type': 'application/json',
			},
		})
		// console.log(data)
		let result = await data.data.message;
		setItems(result)
		setSelectElement(result[1]?._id)
	};
	useEffect(() => {
		allpackage();
	}, []);
	async function resgisterOwner(e) {
		e.preventDefault();
		let bankDetails = { bankName, accountNumber, ifscCode, panNumber }
		let payload = { name, userName, password, confirmPassword, email, restaurantAddress, restaurantCity, bankDetails, restaurantCountry, restaurantName }
		// console.log(payload)
			await fetch("https://node.firehrm.com/FB/flame/admin/RegisterAdmin",
				{
					method: "POST",
					headers: {
						'Content-type': 'application/JSON'
					},
					body: JSON.stringify(payload)
				}).then(res => {
					console.log(res,'res')
					if (res.status == 200 || res.status == 201) {
						toast.success('Your Request Successfully sended. Please wait for Confirmation Mail', {
							position: "bottom-center",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
						setUserName("")
						setName("")
						seteamil("")
						setpassword("")
						setconfirmPassword("")
						setbankName("")
						setaccountNumber("")
						setifscCode("")
						setpanNumber("")
						setrestaurantName("")
						setrestaurantCity("")
						setrestaurantAddress("")
						setrestaurantCountry("")
						// navigate('/getPackage')
					}
					else if (res.status == 400) {
						toast.warn('Already Exist, Try another Email or User Name', {
							position: "bottom-center",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					}
					else if (res.status == 500) {
						toast.danger('Internal Error. Try again', {
							position: "bottom-center",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					}
					else {
						toast.danger('Something went wrong. Try again', {
							position: "bottom-center",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					}
				}).catch(err => {
					console.log(err)

					toast.danger('Something went wrong. Try again', {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				})
		
	}
	return (
		<>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Header />

			<main>
				<div className="hero_single inner_pages background-image" style={{ background: "url(../../assets/img/hero_submit.jpg)" }} >
					<div className="opacity-mask" style={{ background: "rgba(0, 0, 0, 0.6)" }}>
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-xl-9 col-lg-10 col-md-8">
									<h1>Attract New Customers</h1>
									<p>More bookings from diners around the corner</p>
								</div>
							</div>
							{/* <!-- /row --> */}
						</div>
					</div>
				</div>
				{/* <!-- /hero_single --> */}

				<div className="bg_gray">
					<div className="container margin_60_40">
						<div className="main_title center">
							<span><em></em></span>
							<h2>Why Submit to Foogra</h2>
							<p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
						</div>

						<div className="row justify-content-center align-items-center add_bottom_15">
							<div className="col-lg-5">
								<div className="box_about">
									<h3>Boost your Bookings</h3>
									<p className="lead">Est falli invenire interpretaris id, magna libris sensibus mel id.</p>
									<p>Per eu nostrud feugiat. Et quo molestiae persecuti neglegentur. At zril definitionem mei, vel ei choro volumus. An tota nulla soluta has, ei nec essent audiam, te nisl dignissim vel. Ex velit audire perfecto pro, ei mei doming vivendo legendos. Cu sit magna zril, an odio delectus constituto vis. Vis ludus omnesque ne, est veri quaeque ad.</p>
									<img src={arrow_about} alt="" className="arrow_1" />
								</div>
							</div>
							<div className="col-lg-5 pl-lg-5 text-center d-none d-lg-block">
								<img src={about_1} alt="" className="img-fluid" width="250" height="250" />
							</div>
						</div>
						{/* <!-- /row --> */}
						<div className="row justify-content-center align-items-center add_bottom_15">
							<div className="col-lg-5 pr-lg-5 text-center d-none d-lg-block">
								<img src={about_2} alt="" className="img-fluid" width="250" height="250" />
							</div>
							<div className="col-lg-5">
								<div className="box_about">
									<h3>Manage Easly</h3>
									<p className="lead">Est falli invenire interpretaris id, magna libris sensibus mel id.</p>
									<p>Per eu nostrud feugiat. Et quo molestiae persecuti neglegentur. At zril definitionem mei, vel ei choro volumus. An tota nulla soluta has, ei nec essent audiam, te nisl dignissim vel. Ex velit audire perfecto pro, ei mei doming vivendo legendos. Cu sit magna zril, an odio delectus constituto vis. Vis ludus omnesque ne, est veri quaeque ad.</p>
									<img src={arrow_about} alt="" className="arrow_2" />
								</div>
							</div>
						</div>
						{/* <!-- /row --> */}
						<div className="row justify-content-center align-items-center">
							<div className="col-lg-5">
								<div className="box_about">
									<h3>Reach New Customers</h3>
									<p className="lead">Est falli invenire interpretaris id, magna libris sensibus mel id.</p>
									<p>Per eu nostrud feugiat. Et quo molestiae persecuti neglegentur. At zril definitionem mei, vel ei choro volumus. An tota nulla soluta has, ei nec essent audiam, te nisl dignissim vel. Ex velit audire perfecto pro, ei mei doming vivendo legendos. Cu sit magna zril, an odio delectus constituto vis. Vis ludus omnesque ne, est veri quaeque ad.</p>
								</div>

							</div>
							<div className="col-lg-5 pl-lg-5 text-center d-none d-lg-block">
								<img src={about_3} alt="" className="img-fluid" width="250" height="250" />
							</div>
						</div>
						{/* <!-- /row --> */}
					</div>
					{/* <!-- /container --> */}
				</div>
				{/* <!-- /bg_gray --> */}

				<div className="container margin_60_40">
					<div className="main_title center">
						<span><em></em></span>
						<h2>Our Pricing Plans</h2>
						<p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
					</div>
					<div className="row plans">
						{item.map((x) =>
							<div className={selectElement == x._id ? "plan-tall plan col-md-4" : "plan col-md-4"} id={x._id} onClick={(e) => handleClick(x._id)}>
								<div className="plan-title">
									<h3>{x.packageName}</h3>
									<p>{x.description}</p>
								</div>
								<p className="plan-price">{x.amount == 0 ? "Free" : <>&#x20B9; {x.amount}</>}</p>
								<ul className="plan-features">

									<li><strong>Check and go</strong> included</li>
									<li><strong>APP</strong> included</li>
									<li><strong>{x.packagePlan}</strong> valid</li>
									<li><strong>You can Add and Access{" " + x.addAccess}</strong> Restaurant</li>
									<li><strong>Unsubscribe</strong> anytime</li>

								</ul>
								{/* <a href="#submit" className="btn_1 gray btn_scroll">Submit</a> */}
							</div>
						)}
					</div>
					{/* <!-- End row plans--> */}
				</div>
				{/* <!-- /container --> */}

				<div className="bg_gray pattern" id="submit">
					<div className="container margin_60_40">
						<div className="row justify-content-center">
							<div className="col-lg-5">
								<div className="text-center add_bottom_15">
									<h4>Please fill the form below</h4>
									<p>Id persius indoctum sed, audiam verear his in, te eum quot comprehensam. Sed impetus vocibus repudiare et.</p>
								</div>
								<div id="message-register"></div>
								<form  onSubmit={(e) => resgisterOwner(e)}>
									<h6>Personal data</h6>
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												<input type="text" className="form-control" value={name} placeholder="Name and Last Name" name="name_register" id="name_register" onChange={(e) => setName(e.target.value)} />
											</div>
										</div>
									</div>
									{/* <!-- /row --> */}
									<div className="row add_bottom_15">
										<div className="col-lg-12">
											<div className="form-group">
												<input type="email" className="form-control" value={email} placeholder="Email Address" name="email_register" id="email_register" onChange={(e) => seteamil(e.target.value)} />
											</div>
										</div>
									</div>
									{/* <!-- /row --> */}
									<h6>Restaurant data</h6>
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												<input type="text" className="form-control" value={restaurantName} placeholder="Restaurant Name" name="restaurantname_register" id="restaurantname_register" onChange={(e) => setrestaurantName(e.target.value)} />
											</div>
										</div>
									</div>
									{/* <!-- /row --> */}
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group">
												<input type="text" className="form-control" value={restaurantAddress} placeholder="Address" name="address_register" id="address_register" onChange={(e) => setrestaurantAddress(e.target.value)} />
											</div>
										</div>
									</div>
									{/* <!-- /row --> */}
									<div className="row add_bottom_15">
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" className="form-control" value={restaurantCity} placeholder="City" name="city_register" id="city_register" onChange={(e) => setrestaurantCity(e.target.value)} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="text" className="form-control" value={restaurantCountry} placeholder="Country" name="city_register" id="city_register" onChange={(e) => setrestaurantCountry(e.target.value)} />
											</div>

										</div>
									</div>
									<h6>Restaurant Admin Details</h6>
									<div className="row add_bottom_15">
										<div className="col-lg-12">
											<div className="form-group">
												<input type="text" id="verify_register" className="form-control" value={userName} placeholder="Username" onChange={(e) => setUserName(e.target.value)} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="password" className="form-control" value={password} placeholder="Password" name="city_register" id="city_register" onChange={(e) => setpassword(e.target.value)} />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input type="password" className={password == confirmPassword ? "form-control text-success" : "form-control text-"} value={confirmPassword} placeholder="confirm Password" name="city_register" id="city_register" onChange={(e) => setconfirmPassword(e.target.value)} />
											</div>
										</div>
									</div>
									<h6>Bank Details</h6>
									<div className="row add_bottom_15">
										<div className="col-lg-12">
											<div className="form-group">
												<input type="text" id="verify_register" className="form-control" value={bankName} placeholder="Bank Name" onChange={(e) => setbankName(e.target.value)} />
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<input type="text" id="verify_register" className="form-control" value={accountNumber} placeholder="Account Number" onChange={(e) => setaccountNumber(e.target.value)} />
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-group">
												<input type="text" id="verify_register" className="form-control" value={ifscCode} placeholder="IFSC Number" onChange={(e) => setifscCode(e.target.value)} />
											</div>
										</div>
										<div className="col-lg-6">
											<div className="form-group">
												<input type="text" id="verify_register" className="form-control" value={panNumber} placeholder="Pan Card Number" onChange={(e) => setpanNumber(e.target.value)} />
											</div>
										</div>
									</div>
									<div className="form-group text-center">
										
										<button   className="submit">Submit</button>
									</div>

								</form>
							</div> 
						</div>
					</div>
					{/* <!-- /container --> */}
				</div>
				{/* <!-- /bg_gray --> */}

			</main>
			<Footer />
			<div id="toTop"></div>
			{/* <!-- Back to top button --> */}

			<div className="layer"></div>
			{/* <!-- Opacity Mask Menu Mobile --> */}
			<Helmet>
				<script src="asstes/js/common_scripts.min.js"></script>
				<script src="asstes/js/common_func.js"></script>
				<script src="asstes/assets/validate.js"></script>

			</Helmet>
		</>
	);
}

export default Submit_Restaurant;