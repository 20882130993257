import { useState, useEffect } from 'react';
import React from 'react';
import Footer from '../Footer/footer';
import Header from '../Header/header';
import axios from 'axios';
import { Helmet } from 'react-helmet';
const Help = () => {

	const [contactlist, setcontact] = useState([]);
	const [article, setArticle] = useState([]);
	useEffect(() => {

		ContactDetails();
		Articles();
	}, []);
	const ContactDetails = async () => {
		axios({
			'method': 'GET',
			'url': 'https://node.firehrm.com/FB/flame/help/getTopicList',
			'headers': {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				console.log(response.data.message, 'response')
				setcontact(response.data.message)
			})
			.then
			.catch((error) => {
				alert(error)
			}
			)
	}
	const Articles = async () => {
		axios({
			'method': 'GET',
			'url': 'https://node.firehrm.com/FB/flame/help/getArticle',
			'headers': {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				console.log(response.data.message, 'response')
				setArticle(response.data.message)
			})
			.then
			.catch((error) => {
				alert(error)
			}
			)
	}
	return (
		<>
			<Header />
			<main>
				<div className="hero_single inner_pages background-image" style={{ background: "url(../../assets/img/hero_general.jpg)" }}>
					<div className="opacity-mask" style={{ background: "rgba(0, 0, 0, 0.5)" }}>
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-xl-8 col-lg-10 col-md-8">
									<h1>Help and support</h1>
									<p>Search questions or useful articles</p>
									<form>
										<div className="search_bar">
											<input type="text" className="form-control" placeholder="What are you looking for?" />
											<input type="submit" value="Search" />
										</div>
									</form>
								</div>
							</div>
							{/* <!-- /row --> */}
						</div>
					</div>
				</div>
				{/* <!-- /hero_single --> */}

				<div className="bg_gray">
					<div className="container margin_60_40">
						<div className="main_title center">
							<span><em></em></span>
							<h2>Select a topic</h2>
							<p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
						</div>

						<div className="row">
							{contactlist?.map(x =>

								<div className="col-lg-4 col-md-6">
									<a className="box_topic" href="#0">
										<span><i className="icon_wallet"></i></span>
										<h3>{x.topic}</h3>
										<p>{x.description}</p>
									</a>
								</div>
							)}

						</div>
					</div>

					{/* <!-- /container --> */}
				</div>
				{/* <!-- /bg_gray --> */}
				<div className="container margin_60_40">
					<div className="main_title version_2">
						<span><em></em></span>
						<h2>Popular articles</h2>
						<p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
					</div>
					<div className="list_articles add_bottom_25 clearfix">
						{article?.map(x =>

							<ul>
								<li><a href="#0"><i className="icon_documents_alt"></i>{x.articleName}</a></li>

							</ul>
						)}

					</div>
					{/* <!-- /list_articles --> */}
				</div>
				{/* <!-- /container --> */}
			</main>
			<Footer />
			<div id="toTop"></div>
			{/* <!-- Back to top button --> */}

			<div className="layer"></div>
			{/* <!-- Opacity Mask Menu Mobile --> */}
			{/* // // <!-- COMMON SCRIPTS --> */}
			<Helmet>
				<script src="asstes/js/common_scripts.min.js"></script>
				<script src="asstes/js/common_func.js"></script>
				<script src="asstes/assets/validate.js"></script>

			</Helmet>
		</>
	);
}

export default Help;