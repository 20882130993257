import React from 'react';
// import { Navbar } from 'react-bootstrap';
import Footer from '../Footer/footer';
import Header from '../Header/header';
import './profile.css'
const Profile = () => {
    return (<>
  <Header/>
  <div className="container-fluid bgback">
         
  </div>
  <Footer/>
    </>);
}

export default Profile;