import React, { useState } from 'react';
import Header from '../Header/header';
import location_1 from '../../assets/img/location_1.jpg'
import location_2 from '../../assets/img/location_2.jpg'
import location_3 from '../../assets/img/location_3.jpg'
import location_4 from '../../assets/img/location_4.jpg'
import location_5 from '../../assets/img/location_5.jpg'
import location_6 from '../../assets/img/location_6.jpg'
import Footer from '../Footer/footer';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import axios from 'axios';

const token = localStorage.getItem('token');
const Wishlist = () => {
	const [wishList, setwishList] = useState([]);
	useEffect(() => {
		wishListlists()
	}, []);


	async function wishListlists() {
		const data = await axios({
			'method': 'GET',
			'url': `https://node.firehrm.com/FB/flame/restaurant/UserFavoriteList`,
			'headers': {
				'Content-Type': 'application/json',
				'Authorization': token
			},
		})
		console.log(data.data.data1[0].restaurantDetails, 'wishlist');
		setwishList(data.data.data1)
	};
	console.log(wishList)


	return (
		<>
			<Header />
			<main>
				<div className="hero_single inner_pages background-image" style={{ background: "url(../../assets/img/hero_general.jpg)" }}>
					<div className="opacity-mask" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-xl-9 col-lg-10 col-md-8">
									<h1>Your Wishlist</h1>
									<p>Foogra a successful restaurant experience</p>
								</div>
							</div>
							{/* <!-- /row --> */}
						</div>
					</div>
				</div>
				{/* <!-- /hero_single --> */}

				<div className="container margin_60_30">
					<div className="main_title center">
						<span><em></em></span>
						<h2>Listed Restaurants</h2>
						<p>Cum doctus civibus efficiantur in imperdiet deterruisset</p>
					</div>
					<div className="row">
						{wishList.map(x =>
							<div className="col-xl-4 col-lg-6 col-md-6">
								<div className="strip">
									<figure>
										<span className="ribbon off">-{x.restaurantDetails && x.restaurantDetails.offer}%</span>

										<img src={x.restaurantDetails && x.restaurantDetails.restaurantImage} className="lazy" alt="" />
										<a href="detail-restaurant-delivery.html" className="strip_info">
											<small>Pizza</small>
											<div className="item_title">
												<h3>{x.restaurantDetails && x.restaurantDetails.restaurantName}</h3>
												<small>{x.restaurantDetails && x.restaurantDetails.address}</small>
											</div>
										</a>
									</figure>
									<ul>
										{/* <li><a href="#0" className="wish_bt"><i className="icon_trash_alt"></i> Remove</a></li> */}
										<li>
											<div className="score"><span>{x.restaurantDetails && x.restaurantDetails.rating}<em>350 Reviews</em></span><strong>{x.restaurantDetails && x.restaurantDetails.ratingValue}</strong></div>
										</li>
									</ul>
								</div>
							</div>
						)}

						{/* <!-- /box_grid --> */}

					</div>

				</div>
				{/* <!-- /container --> */}

			</main>
			<Footer />
			<div id="toTop"></div>
			{/* <!-- Back to top button --> */}

			<div className="layer"></div>
			{/* <!-- Opacity Mask Menu Mobile --> */}
			<Helmet>
				<script src="asstes/js/common_scripts.min.js"></script>
				<script src="asstes/js/common_func.js"></script>
				<script src="asstes/assets/validate.js"></script>
				<script src="asstes/js/wishList.js"></script>
			</Helmet>
			{/* <script>
		$('.wish_bt').on('click', function (c) {
			$(this).parent().parent().parent().parent().fadeOut('slow', function (c) {});
		});
	</script> */}
		</>

	);
}

export default Wishlist;