import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './homemy.css';
import { Helmet } from "react-helmet";
import Header from '../Header/header';
import Footer from '../Footer/footer';
import useGeolocation from "react-hook-geolocation";
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { timers } from 'jquery';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'



const Home = () => {
    const geolocation = useGeolocation();
    const [Response, setResponse] = useState([])
    const [search, setSearch] = useState()
    const [ResponseOffer, setResponseOffer] = useState([])
    const [arrdata, setarrdata] = useState(["1", "2", "3", "4", "5"])
    const [popFood, setpopFood] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getpopularFood();


    }, []);
    async function circlepopular() {
        const latitude = localStorage.getItem('latitude');
        const longitude = localStorage.getItem('longitude');
        // console.log(geolocation.latitude, geolocation.longitude)
        await axios({
            'method': 'GET',
            'url': 'https://node.firehrm.com/FB/flame/restaurant/getRestaurantLocationByRating?latitude=' + latitude + '&longitude=' + longitude + "&page=1&limit=6",

        })
            .then((res) => {
                // console.log(res.data.message[0].restaurantImage, "getratingapi")
                // console.log(geolocation.latitude, latitude, geolocation.longitude, longitude)
                const response = res.data.data
                console.log(response, "rating");
                // setAPIData(resp);
                setResponse(response)
                // console.log(resp);
                //  setvalue(resp.rating);

            }).catch((err) => {
                console.log("ERR =>", err);
            })
    }
    const getpopularFood = async () => {
        axios({
            'method': 'GET',
            'url': 'https://node.firehrm.com/FB/flame/order/popularFood',
            'headers': {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                // console.log(response.data.data, 'popfood')
                setpopFood(response.data.data)
            })
            .then
            .catch((error) => {
                alert(error)
            }
            )
    }

    async function circleoffer() {
        const latitude = localStorage.getItem('latitude');
        const longitude = localStorage.getItem('longitude');
        // console.log(latitude, longitude)
        await axios({
            'method': 'GET',
            'url': 'https://node.firehrm.com/FB/flame/restaurant/getRestaurantLocationByOffer?latitude=' + latitude + '&longitude=' + longitude + "&page=1&limit=6",

        })
            .then((res) => {
                console.log(res,"offer")
                const resp = res.data.data
                // console.log(resp, "offers");
                // setAPIData(resp);
                // let value6= resp[0].data.splice(0,6)
                setResponseOffer(resp)
                // console.log(resp);
                //  setvalue(resp.rating);
            }).catch((err) => {
                console.log("ERR =>", err);
            })
    }
    useEffect(() => {
        circlepopular();
        circleoffer();
    }, [])
    function nav(e, type) {
        switch (type) {
            case "popularRes":
                navigate('/grid_listing_filterscol', { state: type })
                break;
            case "offerRes":
                navigate('/grid_listing_filterscol', { state: type })
                break;
            case "all":
                navigate('/grid_listing_filterscol_full_masonry', { state: type })
                break;
            default:
                break;
        }

    }

    // const url='assets/js/common_scripts.min.js';
    // const url2='../../../public/assets/js/common_func.js';
    // useEffect(() => {
    //     function script(){
    //         let script = document.createElement("script");
    //         // script.async = true;
    //         script.src =url;
    //         script.src =url2;
    //         script.type='text/javascipt'
    //         // script.onload = () => this.scriptLoaded();
    //         document.getElementsByTagName('head')[0].appendChild(script);
    //     }
    //   script();
    // })
    // useEffect(() => {
    //     const script = document.createElement('script');

    //     script.src = '../../../public/assets/js/common_scripts.min.js';
    //     script.async = true;
    //     script.onload = () => this.scriptLoaded();
    //     document.body.appendChild(script);

    //     // return () => {
    //     //   document.body.removeChild(script);
    //     // }
    //   }, ['../../../public/assets/js/common_scripts.min.js']);
    return (

        <>
            <Header />
            <main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="hero_single version_2 home">
                    <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)" style={{ background: "rgba(0, 0, 0, 0.6)" }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-10 col-lg-12 col-md-8">
                                    <h1>Discover &amp; Book</h1>
                                    <p>The best restaurants at the best price</p>
                                    <form>
                                        <div className="row no-gutters custom-search-input">
                                            <div className="col-lg-10">
                                                <div className="form-group">
                                                    <input className="form-control" type="text" placeholder="What are you looking for Restaurant and Food etc ... " onChange={(e) => setSearch(e.target.value)} />
                                                    <i className="icon_search"></i>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <input type="submit" value="Search" onClick={(e) => navigate('grid_listing_filterscol', { state: { type: 'search', search } })} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg_gray">
                    <div className="container margin_60_40">
                        <div className="main_title center">
                            <span><em></em></span>
                            <h2>Popular Categories</h2>
                            <p>Cum doctus civibus efficiantur in imperdiet deterruisset</p>
                        </div>
                        <div >
                            <OwlCarousel items={4}
                                className="owl-carousel owl-theme categories_carousel"
                                loop
                                nav
                                margin={8} >
                                {popFood.map(x =>
                                    <div className="item">
                                        <a href="">
                                            <span>{x.count}</span>
                                            {/* <i className="icon-food_icon_pizza"></i> */}
                                            <img src={x.foodImage} className="owl-lazy" alt="" style={{ opacity: 1, height: '185px' }} />
                                            <h3>{x.category}</h3>
                                            <small>Avg price ${x.foodPrice}</small>
                                        </a>
                                    </div>
                                )}

                            </OwlCarousel>
                        </div>
                    </div>
                </div>

                <div className="container margin_60_40">
                    <div className="main_title">
                        <span><em></em></span>
                        <h2>Popular Restaurants</h2>
                        <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
                        <a href='' onClick={(e) => nav(e, "popularRes")}>View All</a>
                    </div>
                    <div >
                        <OwlCarousel items={3}
                            className="owl-carousel owl-theme carousel_4"
                            loop
                            nav
                            margin={8} >
                            {Response.length > 0 && Response.map((item) =>
                                <div className="item">
                                    <div className="strip">
                                        <figure>
                                            <span className="ribbon off">-{item.offer}%</span>
                                            <img src={item.restaurantImage} className="owl-lazy" alt="" style={{ opacity: 1 }} />
                                            <a className="strip_info">
                                                <small>{item.category[0].categoryName}</small>
                                                <div className="item_title">
                                                    <h3>{item.restaurantName}</h3>
                                                    <small>{item.address}</small>
                                                </div>
                                            </a>
                                        </figure>
                                        <ul>
                                            <li><span className="loc_open">Now Open</span></li>
                                            <li>
                                                <div className="score"><span>Superb<em>350 Reviews</em></span><strong>{item.rating}</strong></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </OwlCarousel>
                    </div>
                    <div className="banner lazy" data-bg="url(../../assets/img/banner_bg_desktop.jpg)" style={{ background: "url(../../assets/img/banner_bg_desktop.jpg)" }}>
                        <div className="wrapper d-flex align-items-center opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.2)">
                            <div>
                                <small>Flamebee</small>
                                <h3>More than 3000 Restaurants</h3>
                                <p>Book a table easly at the best price</p>
                                <a href='' onClick={(e) => nav(e, "all")} className="btn_1">View All</a>
                            </div>
                        </div>
                        {/* <!-- /wrapper --> */}
                    </div>
                    {/* <!-- /banner --> */}

                    <div className="row">
                        <div className="col-12">
                            <div className="main_title version_2">
                                <span><em></em></span>
                                <h2>Our Very Best Deals</h2>
                                <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
                                {/* <a href="#0">View All</a> */}
                            </div>
                        </div>
                        {ResponseOffer.map(x =>

                            <div className="col-md-6">

                                <div className="list_home">

                                    <ul>
                                        <li>
                                            <a >
                                                <figure>
                                                    <img src={x.restaurantImage} alt="" className="lazy" style={{ opacity: 1 }} />
                                                </figure>
                                                <div className="score"><strong>{x.rating}</strong></div>
                                                <em>{x.category[0].categoryName}</em>
                                                <h3>{x.restaurantName}</h3>
                                                <small>{x.address}</small>
                                                <ul>
                                                    <li><span className="ribbon off">-{x.offer}%</span></li>
                                                    <li>Average price ${x.averagePrice}</li>
                                                </ul>
                                            </a>
                                        </li>
                                    </ul>

                                </div>

                            </div>
                        )}
                    </div>
                    {/* <!-- /row --> */}
                    <p className="text-center d-block d-md-block d-lg-none"><a href='' onClick={(e) => nav(e, "popularRes")} className="btn_1">View All</a></p>
                    {/* <!-- /button visibile on tablet/mobile only --> */}
                    {/* </div> */}
                    {/* <!-- /container --> */}
                    <div className="call_section lazy" data-bg="url(../../assets/img/bg_call_section.jpg)" style={{ background: "url(../../assets/img/bg_call_section.jpg)" }}>
                        <div className="container clearfix">
                            <div className="col-lg-5 col-md-6 float-right wow">
                                <div className="box_1">
                                    <h3>Are you a Restaurant Owner?</h3>
                                    <p>Join Us to increase your online visibility. You'll have access to even more customers who are looking to enjoy your tasty dishes at home.</p>
                                    <a href="" onClick={(e) => navigate('submit-restaurant')} className="btn_1">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
            <div id="toTop"></div>
            <div className="layer"></div>
            <Helmet>
                <script src='assets/js/common_scripts.min.js' type="text/javascript" />
                <script src='assets/js/common_func.js' type="text/javascript" />
            </Helmet>
        </>
    );
}
export default Home;