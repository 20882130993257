import React from 'react';
import { useState, useEffect } from 'react';

import Footer from '../Footer/footer';
import Header from '../Header/header';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { TimePicker } from 'react-time-picker';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
	const [contactlist, setcontact] = useState([]);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	useEffect(() => {
		ContactDetails();
	}, []);
	const ContactDetails = async () => {
		axios({
			'method': 'GET',
			'url': 'https://node.firehrm.com/FB/flame/superAdmin/getContact',
			'headers': {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				console.log(response.data.message, 'response')
				setcontact(response.data.message)
			})
			.then
			.catch((error) => {
				alert(error)
			}
			)
	}
	async function contactMe(e) {
		e.preventDefault();
		let payload = { name, message, email }
		console.log(payload)
		await fetch("https://node.firehrm.com/FB/flame/superAdmin/createContactForm",
			{
				method: "POST",
				headers: {
					'Content-type': 'application/JSON'
				},
				body: JSON.stringify(payload)
			}).then(res => {
				console.log(res)
				if (res.status == 200 || res.status == 201) {
					toast.success('Your Request Successfully sended. Please wait for Confirmation Mail', {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					// navigate('/getPackage')
				}
				else if (res.status == 400) {

					toast.warn('Already Exist, Try another Email or User Name', {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});

				}
				else if (res.status == 500) {

					toast.danger('Internal Error. Try again', {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
				else {

					toast.danger('Something went wrong. Try again', {
						position: "bottom-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			}).catch(err => {
				console.log(err)

				toast.danger('Something went wrong. Try again', {
					position: "bottom-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
	}
	return (
		<>
			<Header />
			<main>
				<ToastContainer
					position="top-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<div className="hero_single inner_pages background-image" style={{ background: "url(../../assets/img/home_section_1.jpg)" }} >
					<div className="opacity-mask" style={{ background: "rgba(0, 0, 0, 0.6)" }}>
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-xl-9 col-lg-10 col-md-8">
									<h1>Contact Foogra</h1>
									<p>A successful restaurant experience</p>
								</div>
							</div>
							{/* <!-- /row --> */}
						</div>
					</div>
				</div>
				{/* <!-- /hero_single --> */}
				<div className="bg_gray">
					<div className="container margin_60_40">
						{contactlist?.map(x =>
							<div className="row justify-content-center">
								<div className="col-lg-4">
									<div className="box_contacts">
										<i className="icon_lifesaver"></i>
										<h2>Help Center</h2>
										<a href="#0">{x.helpCenter.phoneNo}</a> - <a href="#0">{x.helpCenter.email}</a>
										<small>{x.helpCenter.time}</small>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="box_contacts">
										<i className="icon_pin_alt"></i>
										<h2>Address</h2>
										<div>{x.location.address}</div>
										<small>{x.location.time}</small>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="box_contacts">
										<i className="icon_cloud-upload_alt"></i>
										<h2>Submissions</h2>
										<a href="#0">{x.submission.phoneNo}</a> - <a href="#0">{x.submission.email}</a>
										<small>{x.submission.time}</small>
									</div>
								</div>
							</div>
						)}

						{/* <!-- /row --> */}
					</div>
					{/* <!-- /container --> */}
				</div>
				{/* <!-- /bg_gray --> */}
				<div className="container margin_60_40">
					<h5 className="mb_5">Drop Us a Line</h5>
					<div className="row">
						<div className="col-lg-4 col-md-6 add_bottom_25">
							<div id="message-contact"></div>
							<form onSubmit={(e) => contactMe(e)}>
								<div className="form-group">
									<input className="form-control" type="text" placeholder="Name" id="name_contact" name="name_contact" onChange={(e) => setName(e.target.value)} />
								</div>
								<div className="form-group">
									<input className="form-control" type="email" placeholder="Email" id="email_contact" name="email_contact" onChange={(e) => setEmail(e.target.value)} />
								</div>
								<div className="form-group">
									<textarea className="form-control" style={{ height: "150px" }} placeholder="Message" id="message_contact" name="message_contact" onChange={(e) => setMessage(e.target.value)}></textarea>
								</div>
								{/* <div className="form-group">
									<input className="form-control" type="text" id="verify_contact" name="verify_contact" placeholder="Are you human? 3 + 1 =" />
								</div> */}
								<div className="form-group">
									<input className="btn_1 full-width" type="submit" value="Submit" id="submit-contact" />
								</div>
							</form>
						</div>
						<div className="col-lg-8 col-md-6 add_bottom_25">
							<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15720.468256243432!2d78.0898839!3d9.9242076!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbfea2df6e2012937!2sFire%20Bee%20Techno%20Services%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1648461560041!5m2!1sen!2sin" width="800" height="375" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
						</div>
					</div>
					{/* <!-- /row --> */}
				</div>
				{/* <!-- /container --> */}
			</main>
			<Footer />
			<div id="toTop"></div>
			{/* <!-- Back to top button --> */}

			<div classNameName="layer"></div>
			{/* <!-- Opacity Mask Menu Mobile --> */}
			<Helmet>
				<script src="assets/js/common_scripts.min.js"></script>
				<script src="assets/js/common_func.js"></script>
				<script src="assets/assets/validate.js"></script>
			</Helmet>
		</>
	);
}

export default Contact;