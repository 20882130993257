import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Footer from '../Footer/footer';
import Header from '../Header/header';
import './detail-restaurant-delivery.css'
import { Button, Modal } from "react-bootstrap";
import TimePicker from 'react-time-picker';
import { useNavigate } from 'react-router-dom';
import CartItems from './cartitems';
import { useLocation } from 'react-router-dom';
import avatar from '../../assets/img/avatar.jpg'
import avatar1 from '../../assets/img/avatar1.jpg'
import avatar4 from '../../assets/img/avatar4.jpg'
import avatar6 from '../../assets/img/avatar6.jpg';
import { ToastContainer, toast } from 'react-toastify';

const ResDetail = () => {
    const { state } = useLocation();
    console.log(state, 'state')
    const nav = useNavigate()

    const [pay, setPay] = useState({});
    const [wishlistStatus, setwishlistStatus] = useState(false);
    const [deliveryTime, setdeliveryTime] = useState()
    const [totalPrice, setTotal] = useState()
    const [offer, setoffer] = useState()
    const [cart, setCart] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = (e) => { e.preventDefault(); setShow(false); }
    const handleShow = (e) => { e.preventDefault(); setShow(true); }
    const [limit, setLimit] = useState(2000)
    const [item, setItems] = useState([])
    const [items, setItem] = useState([])
    const [deliveryDate, setDeliverydate] = useState('')
    const [deliveryType, setDeliveryType] = useState('');
    const [cartList, setCartList] = useState([]);
    const jsonItems1 = JSON.parse(localStorage.getItem('cart1'));


    useEffect(() => {
        getdetails();
        getFoodlist();
        // GetCartList();
    }, [])
    const [userinfo, setUserInfo] = useState({
        languages: [],

    });

    const handleChange = (e, list, y) => {
        console.log(list)
        if (list.status == "false") {
            list.status = "true";
            console.log(list)
        } else {
            list.status = "false";
            console.log(list)
        }
        const ingredientIndex = y.option[0].addIngredient.findIndex((x) => x._id == list._id)
        console.log(ingredientIndex)
        y.option[0].addIngredient[ingredientIndex] = list;
        console.log(y)
        const indexOfFood = item.findIndex((x) => x._id == y._id)
        console.log(indexOfFood)
        item[indexOfFood] = y
        console.log(item)
    };
    const addRemoveEvent = (item, d) => {
        // console.log(cart, 'cart')
        const ind = cart.indexOf(item);
        const arr = cart;
        arr[ind].foodTimes += d;
        if (arr[ind].foodTimes <= 0) {
            console.log(cart.filter((x) => x != item), 'filter')
            setCart(cart.filter((x) => x != item));
            arr[ind].foodTimes = 1;
        }
        else {
            localStorage.setItem('cart', JSON.stringify(arr));
            const jsonItems = JSON.parse(localStorage.getItem('cart'));
            console.log(jsonItems, 'local')
            setCart(jsonItems)
        }
    };

    async function wishlist(e, id) {
        e.preventDefault();
        const token = localStorage.getItem('token');
        switch (wishlistStatus) {
            case true:
                let payload = { interested: false }
                let result = await fetch('https://node.firehrm.com/FB/flame/restaurant/createInterestedPersons/' + id,
                    {
                        method: "POST",
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(payload)
                    }).then((result) => {
                        setwishlistStatus(false)
                        console.log(result,"101")})
                    .catch((err) => console.log(err))
                // console.log(result, 'result')
                // const res = await result.json()
                // console.log(res)
                break;
            case false:
                let payload1 = { interested: true }
                await fetch('https://node.firehrm.com/FB/flame/restaurant/createInterestedPersons/' + id,
                    {
                        method: "POST",
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(payload1)
                    }).then((res) => {
                        setwishlistStatus(true)
                        console.log(res,"116")})
                    .catch((err) => console.log(err))

                break;

            default:
                break;
        }
    }
    // async function CreateCheckout(e, id) {
    //     e.preventDefault();
    //     const token = localStorage.getItem('token');
    //     let payload = { cart }
    //     console.log(cart, 'cart')
    //     let result = await fetch('http://https://node.firehrm.com/FB/flame/payment/checkOut',
    //         {
    //             method: "POST",
    //             headers: {
    //                 'Authorization': token,
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(payload)
    //         })
    //     console.log(result, 'result')
    //     const res = await result.json()
    //     console.log(res)

    // }
    // const GetCartList = async () => {
    //     axios({
    //         'method': 'GET',
    //         'url': 'http://https://node.firehrm.com/FB/flame/payment/getCheckOutList ',
    //         headers: {
    //             "content-type": "application/json",
    //         },
    //     })
    //         .then((response) => {
    //             console.log(response.data.message, 'getcart')
    //             setCartList(response.data.message)
    //         })
    //         .then
    //         .catch((error) => {
    //             alert(error)
    //         }
    //         )
    // }
    function addcart(e, list) {
        e.preventDefault()
        if (cart.filter((x) => x._id == list._id).length == 0) {
            localStorage.setItem("cart1", JSON.stringify([...cart, list]));
            const jsonItems1 = JSON.parse(localStorage.getItem('cart1'));
            setCart(jsonItems1)
        }
    }
    useEffect(() => {
        handlePrice()
    }, [cart])
    // function handlePrice() {
    //     const ans = 0;
    //     setoffer(ans * (state.offer ))
    //     console.log(state.offer, 'state')
    //     setTotal(ans.toFixed(2) - (ans * (state.offer/100)));
    //     console.log(total,'total')
    // };
    function handlePrice() {
        let ans = 0;
        console.log(cart.map((item) => (ans += (item.foodPrice * item.foodTimes))))
        setoffer(ans * (state.offer / 100))
        setTotal(ans.toFixed(2) - (ans * (state.offer / 100)));
    };

    async function getdetails() {
        await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/restaurant/getOneRestaurantById/` + state._id,
            'headers': {
                'Content-Type': 'application/json',
            },
        }).then(data => {
            console.log(data.data.data,"195")
            setItem(data.data.data);
        })
    }
    async function getFoodlist() {
        await axios({
            'method': 'GET',
            'url': `https://node.firehrm.com/FB/flame/restaurant/getOneRestaurantById/` + state._id,
            'headers': {
                'Content-Type': 'application/json',
            },
        }).then(data => {
            console.log(data.data.data, 'category')
            const a =    data.data.data.foodList.map((x)=>{
                console.log("line 208",x.category)
                if(x.category=="Starters"){
                   const starters = x 
                   console.log("starters",starters)
                }
                else if(x.category=="Main Courses"){
                    const mainCourses = x
                    console.log("Main courses",mainCourses)
                }
                else{
                    const desserts = x
                    console.log("desserts",desserts)
                }
                return x
            })
            setItems(a);
            console.log(a)
        })
    }
    async function order(e) {
        e.preventDefault();
        const token = localStorage.getItem('token');

        let payload = { cart, totalPrice }
        console.log(payload, 'pcart')
        if (token != undefined || token != null) {

            let result = await fetch('https://node.firehrm.com/FB/flame/payment/checkOut',
                {
                    method: "POST",
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
            const res = await result.json()
            console.log(result, 'checkout')

            if (result.status == 200) {
                // console.log(totalPrice,'totalp')
                nav('/booking_delivery_2', { state: totalPrice })
            }
        }
        else {
            toast.warn('Please Login', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <>
            <>
                <Header />
                {Object.keys(state).length != 0 && state != undefined &&
                    <main >
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick={false}
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <div className="hero_in detail_page background-image" style={{ background: `url(` + items.restaurantImage + `)`, size: "cover" }}>
                            <div className="wrapper opacity-mask" style={{ background: "rgba(0, 0, 0, 0.5)" }}>

                                <div className="container">
                                    <div className="main_info">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-5 col-md-6">
                                                <div className="head"><div className="score"><span>Superb<em>{state.review.length} Reviews</em></span><strong>{state.rating}</strong></div></div>
                                                <h1>{items?.restaurantName}</h1>
                                                {items?.restaurantName} - <a href="https://www.google.com/maps/dir//Assistance+%E2%80%93+H%C3%B4pitaux+De+Paris,+3+Avenue+Victoria,+75004+Paris,+Francia/@48.8606548,2.3348734,14z/data=!4m15!1m6!3m5!1s0x47e66e1de36f4147:0xb6615b4092e0351f!2sAssistance+Publique+-+H%C3%B4pitaux+de+Paris+(AP-HP)+-+Si%C3%A8ge!8m2!3d48.8568376!4d2.3504305!4m7!1m0!1m5!1m1!1s0x47e67031f8c20147:0xa6a9af76b1e2d899!2m2!1d2.3504327!2d48.8568361" target="blank">Get directions</a>
                                            </div>
                                            <div className="col-xl-8 col-lg-7 col-md-6">
                                                <div className="buttons clearfix">
                                                    <span className="magnific-gallery">
                                                        <a href="../../assets/img/detail_1.jpg" className="btn_hero" title="Photo title" data-effect="mfp-zoom-in"><i className="icon_image"></i>View photos</a>
                                                        <a href="../../assets/img/detail_2.jpg" title="Photo title" data-effect="mfp-zoom-in"></a>
                                                        <a href="../../assets/img/detail_3.jpg" title="Photo title" data-effect="mfp-zoom-in"></a>
                                                    </span>
                                                    <a href="" className="btn_hero wishlist" onClick={(e) => wishlist(e, state._id)}><i className="icon_heart"></i>Wishlist</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container margin_detail">
                            <div className="row">
                                <div className="col-lg-8">

                                    <div className="tabs_detail">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a id="tab-A" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">Menu</a>
                                            </li>
                                            <li className="nav-item">
                                                <a id="tab-A" href="#pane-B" className="nav-link" data-toggle="tab" role="tab">Information</a>
                                            </li>
                                            <li className="nav-item">
                                                <a id="tab-B" href="#pane-C" className="nav-link" data-toggle="tab" role="tab">Reviews</a>
                                            </li>
                                        </ul>

                                        <div className="tab-content" role="tablist">
                                            <div id="pane-A" className="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
                                                <div className="card-header" role="tab" id="heading-A">
                                                    <h5>
                                                        <a className="collapsed" data-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
                                                            Menu
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapse-A" className="collapse" role="tabpanel" aria-labelledby="heading-A">
                                                    <div className="card-body info_content">
                                                        <p>{items?.aboutMenu}</p>
                                                        <div className="add_bottom_25"></div>
                                                        <h2>Menu</h2>
                                                        {state.category.length > 0 && state.category.map((x =>
                                                            <>
                                                                <h3>{x.categoryName}</h3>
                                                                <p>
                                                                    {x.categoryDetails}
                                                                </p>
                                                        {/* {state.category.length > 0 && state.category.map((x => */}
                                                        {/* <> */}
                                                            {/* <h3>Food Items</h3> */}
                                                            {/* <p> */}
                                                                {/* {x.categoryDetails} */}
                                                            {/* </p> */}
                                                            <table className="table table-striped cart-list">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            Item
                                                                        </th>
                                                                        <th>
                                                                            Price
                                                                        </th>
                                                                        <th>
                                                                            Order
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {item.length > 0 && item != null && item?.map(y=>
                                                                       
                                                                        <>
                                                                          {y.category == x.categoryName ?
                                                                          <tr>
                                                                              
                                                                                <td className="d-md-flex align-items-center">
                                                                                    <figure><img src={y.foodImage} alt="thumb" className="lazy" /></figure>
                                                                                    <div className="flex-md-column">
                                                                                        <h4>{y.foodName}</h4>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <strong>Rs. {y.foodPrice}</strong>
                                                                                </td>
                                                                                <td className="options">
                                                                                    <a type="button" onClick={(e) => { addcart(e, y) }}><i className="icon_plus_alt2"></i></a>
                                                                                </td>

                                                                            </tr>:console.log("error")
                                                                            }
                                                                                       
                                                                            
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                           
                                                        </>
                                                         ))}
                                                        {/* ))} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="pane-B" className="card tab-pane" role="tabpanel" aria-labelledby="tab-B">
                                                <div className="card-header" role="tab" id="heading-B">
                                                    <h5>
                                                        <a className="collapsed" data-toggle="collapse" href="#collapse-B" aria-expanded="true" aria-controls="collapse-B">
                                                            Information
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapse-B" className="collapse" role="tabpanel" aria-labelledby="heading-B">
                                                    <div className="card-body info_content">
                                                        <p>{state.information}</p>
                                                        <div className="add_bottom_25"></div>
                                                        <h2>Pictures from our users</h2>
                                                        <div className="pictures magnific-gallery clearfix">
                                                            {
                                                                state.menuImage?.length > 0 && state.menuImage.map((x) =>
                                                                    <figure><a href="" title="Photo title" data-effect="mfp-zoom-in"><img src={x} className="lazy" alt="" /></a></figure>

                                                                )}
                                                        </div>
                                                        <div className="other_info">
                                                            <h2>How to get to Pizzeria Alfredo</h2>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <h3>Address</h3>
                                                                    <p>{state.address}<br /><a href="https://www.google.com/maps/dir//Assistance+%E2%80%93+H%C3%B4pitaux+De+Paris,+3+Avenue+Victoria,+75004+Paris,+Francia/@48.8606548,2.3348734,14z/data=!4m15!1m6!3m5!1s0x47e66e1de36f4147:0xb6615b4092e0351f!2sAssistance+Publique+-+H%C3%B4pitaux+de+Paris+(AP-HP)+-+Si%C3%A8ge!8m2!3d48.8568376!4d2.3504305!4m7!1m0!1m5!1m1!1s0x47e67031f8c20147:0xa6a9af76b1e2d899!2m2!1d2.3504327!2d48.8568361" target="blank"><strong>Get directions</strong></a></p>
                                                                    <strong>Follow Us</strong><br />
                                                                    <p className="follow_us_detail"><a href="#0"><i className="social_facebook_square"></i></a><a href="#0"><i className="social_instagram_square"></i></a><a href="#0"><i className="social_twitter_square"></i></a></p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <h3>Opening and Closing Time</h3>
                                                                    <p>{state.restaurantTime.openingTime + " to " + state.restaurantTime.closingTime}</p>

                                                                    <p><span className="loc_closed">{state.leaveDay} Closed</span></p>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <h3>Services</h3>
                                                                    <p><strong>Credit Cards</strong><br /> {state.service}</p>
                                                                    <p><strong>Other</strong><br />{state.commonService}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="pane-C" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-C">
                                                <div className="card-header" role="tab" id="heading-C">
                                                    <h5>
                                                        <a className="collapsed" data-toggle="collapse" href="#collapse-C" aria-expanded="false" aria-controls="collapse-C">
                                                            Reviews
                                                        </a>
                                                    </h5>
                                                </div>
                                                <div id="collapse-C" className="collapse" role="tabpanel" aria-labelledby="heading-C">
                                                    <div className="card-body reviews">
                                                        <div className="row add_bottom_45 d-flex align-items-center">
                                                            <div className="col-md-3">
                                                                <div id="review_summary">
                                                                    <strong>8.5</strong>
                                                                    <em>Superb</em>
                                                                    <small>Based on 4 reviews</small>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9 reviews_sum_details">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <h6>Food Quality</h6>
                                                                        <div className="row">
                                                                            <div className="col-xl-10 col-lg-9 col-9">
                                                                                <div className="progress">
                                                                                    <div className="progress-bar" role="progressbar" style={{ width: "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-2 col-lg-3 col-3"><strong>9.0</strong></div>
                                                                        </div>
                                                                        <h6>Service</h6>
                                                                        <div className="row">
                                                                            <div className="col-xl-10 col-lg-9 col-9">
                                                                                <div className="progress">
                                                                                    <div className="progress-bar" role="progressbar" style={{ width: "95%" }} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-2 col-lg-3 col-3"><strong>9.5</strong></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <h6>Location</h6>
                                                                        <div className="row">
                                                                            <div className="col-xl-10 col-lg-9 col-9">
                                                                                <div className="progress">
                                                                                    <div className="progress-bar" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-2 col-lg-3 col-3"><strong>6.0</strong></div>
                                                                        </div>
                                                                        <h6>Price</h6>
                                                                        <div className="row">
                                                                            <div className="col-xl-10 col-lg-9 col-9">
                                                                                <div className="progress">
                                                                                    <div className="progress-bar" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-2 col-lg-3 col-3"><strong>6.0</strong></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div id="reviews">
                                                            <div className="review_card">
                                                                <div className="row">
                                                                    <div className="col-md-2 user_info">
                                                                        <figure><img src={avatar4} alt="" /></figure>
                                                                        <h5>Lukas</h5>
                                                                    </div>
                                                                    <div className="col-md-10 review_content">
                                                                        <div className="clearfix add_bottom_15">
                                                                            <span className="rating">8.5<small>/10</small> <strong>Rating average</strong></span>
                                                                            <em>Published 54 minutes ago</em>
                                                                        </div>
                                                                        <h4>"Great Location!!"</h4>
                                                                        <p>Eos tollit ancillae ea, lorem consulatu qui ne, eu eros eirmod scaevola sea. Et nec tantas accusamus salutatus, sit commodo veritus te, erat legere fabulas has ut. Rebum laudem cum ea, ius essent fuisset ut. Viderer petentium cu his. Tollit molestie suscipiantur his et.</p>
                                                                        <ul>
                                                                            <li><a href="#0"><i className="icon_like"></i><span>Useful</span></a></li>
                                                                            <li><a href="#0"><i className="icon_dislike"></i><span>Not useful</span></a></li>
                                                                            <li><a href="#0"><i className="arrow_back"></i> <span>Reply</span></a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review_card">
                                                                <div className="row">
                                                                    <div className="col-md-2 user_info">
                                                                        <figure><img src={avatar6} alt="" /></figure>
                                                                        <h5>Lukas</h5>
                                                                    </div>
                                                                    <div className="col-md-10 review_content">
                                                                        <div className="clearfix add_bottom_15">
                                                                            <span className="rating">8.5<small>/10</small> <strong>Rating average</strong></span>
                                                                            <em>Published 10 Oct. 2019</em>
                                                                        </div>
                                                                        <h4>"Awesome Experience"</h4>
                                                                        <p>Eos tollit ancillae ea, lorem consulatu qui ne, eu eros eirmod scaevola sea. Et nec tantas accusamus salutatus, sit commodo veritus te, erat legere fabulas has ut. Rebum laudem cum ea, ius essent fuisset ut. Viderer petentium cu his. Tollit molestie suscipiantur his et.</p>
                                                                        <ul>
                                                                            <li><a href="#0"><i className="icon_like"></i><span>Useful</span></a></li>
                                                                            <li><a href="#0"><i className="icon_dislike"></i><span>Not useful</span></a></li>
                                                                            <li><a href="#0"><i className="arrow_back"></i> <span>Reply</span></a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="review_card">
                                                                <div className="row">
                                                                    <div className="col-md-2 user_info">
                                                                        <figure><img src={avatar1} alt="" /></figure>
                                                                        <h5>Marika</h5>
                                                                    </div>
                                                                    <div className="col-md-10 review_content">
                                                                        <div className="clearfix add_bottom_15">
                                                                            <span className="rating">9.0<small>/10</small> <strong>Rating average</strong></span>
                                                                            <em>Published 11 Oct. 2019</em>
                                                                        </div>
                                                                        <h4>"Really great dinner!!"</h4>
                                                                        <p>Eos tollit ancillae ea, lorem consulatu qui ne, eu eros eirmod scaevola sea. Et nec tantas accusamus salutatus, sit commodo veritus te, erat legere fabulas has ut. Rebum laudem cum ea, ius essent fuisset ut. Viderer petentium cu his. Tollit molestie suscipiantur his et.</p>
                                                                        <ul>
                                                                            <li><a href="#0"><i className="icon_like"></i><span>Useful</span></a></li>
                                                                            <li><a href="#0"><i className="icon_dislike"></i><span>Not useful</span></a></li>
                                                                            <li><a href="#0"><i className="arrow_back"></i> <span>Reply</span></a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="row reply">
                                                                    <div className="col-md-2 user_info">
                                                                        <figure><img src={avatar} alt="" /></figure>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <div className="review_content">
                                                                            <strong>Reply from Foogra</strong>
                                                                            <em>Published 3 minutes ago</em>
                                                                            <p><br />Hi Monika,<br /><br />Eos tollit ancillae ea, lorem consulatu qui ne, eu eros eirmod scaevola sea. Et nec tantas accusamus salutatus, sit commodo veritus te, erat legere fabulas has ut. Rebum laudem cum ea, ius essent fuisset ut. Viderer petentium cu his. Tollit molestie suscipiantur his et.<br /><br />Thanks</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <!-- /review_card --> */}
                                                        </div>
                                                        {/* <!-- /reviews --> */}
                                                        <div className="text-right"><a href="" onClick={(e) => handleShow(e)} className="btn_1">Leave a review</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- /tab-content --> */}
                                    </div>
                                    {/* <!-- /tabs_detail --> */}
                                </div>
                                {/* <!-- /col --> */}
                                <div className="col-lg-4" id="sidebar_fixed">
                                    <div className="box_booking">
                                        <div className="head">
                                            <h3>Order Summary</h3>
                                            {state.offer != 0 && state.offer != null && state.offer != undefined &&
                                                <div className="offer">Up to -{state.offer}% off</div>
                                            }
                                        </div>

                                        <div className="main">
                                            {cart.length > 0 ?
                                                <>
                                                    <ul className="clearfix">
                                                        {console.log(cart, 'cart1')}
                                                        {cart.map((x) =>
                                                            <>
                                                                <li>
                                                                    <button className='removeTimes' onClick={(e) => addRemoveEvent(x, -1)}><i className="icon_minus_alt2 fonticon" aria-hidden="true"></i></button><button className='AddTimes' onClick={(e) => addRemoveEvent(x, +1)}><i className="icon_plus_alt2 fonticon" aria-hidden="true"></i></button>  {x.foodTimes}x{x.foodName}
                                                                    <span>{"Rs." + x.foodTimes * x.foodPrice}</span>
                                                                </li>
                                                            </>
                                                        )
                                                        }
                                                    </ul>
                                                    <ul className="clearfix">
                                                        <li>Sub Offer<span>- Rs.{offer}</span></li>
                                                        {/* <li>Delivery fee<span>$10</span></li> */}
                                                        <li className="total">Total<span>Rs.{totalPrice}</span></li>
                                                    </ul>
                                                </> : <p className='text-center'>Add Food to Cart</p>}
                                            <div className="row opt_order">
                                                <div className="col-6">
                                                    <label className="container_radio"><small>Delivery</small>
                                                        <input type="radio" value="delivery" name="opt_order" onChange={(e) => {
                                                            setDeliveryType(e.target.value);
                                                            console.log(deliveryType)
                                                        }
                                                        }
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="col-6">
                                                    <label className="container_radio"><small>Take away</small>
                                                        <input type="radio" value="takeAway" name="opt_order"
                                                            // onClick={(e)=>deliveryTypeChange(e,"takeAway")}  
                                                            onChange={(e) => {
                                                                setDeliveryType(e.target.value)
                                                                console.log(deliveryType)
                                                                // deliveryTypeChange(e,"takeAway")
                                                            }}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <div className="dropdown day">
                                                <a href="#" data-toggle="dropdown">Day <span id="selected_day"></span></a>
                                                <div className="dropdown-menu">
                                                    <div className="dropdown-menu-content">
                                                        <h4>Which day delivered?</h4>
                                                        <div className="radio_select chose_day">
                                                            <ul>
                                                                <li>
                                                                    <input type="radio" id="day_1" name="day" value="Today" onChange={(e) => { setDeliverydate(e.target.value); console.log(deliveryDate) }} />
                                                                    <label for="day_1">Today</label>
                                                                </li>
                                                                <li>
                                                                    <input type="radio" id="day_2" name="day" value="Tomorrow" onChange={(e) => { setDeliverydate(e.target.value); console.log(deliveryDate) }} />
                                                                    <label for="day_2">Tomorrow</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <!-- /dropdown --> */}
                                            {/* <div className="dropdown time">
                                                <a href="#" data-toggle="dropdown">Time<span id="selected_time"></span></a>
                                                <div className="dropdown-menu">
                                                    <div className="dropdown-menu-content">
                                                        <input type="text" placeholder='Time' value={deliveryTime} onChange={(e) => setdeliveryTime(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <!-- /dropdown --> */}
                                            <a href="" onClick={(e) => order(e)} className="btn_1 full-width mb_5">checkout</a>
                                            <div className="text-center"><small>No money charged on this steps</small></div>
                                        </div>
                                    </div>
                                    {/* <!-- /box_booking --> */}
                                    <ul className="share-buttons">
                                        <li><a className="fb-share" href="#0"><i className="social_facebook"></i> Share</a></li>
                                        <li><a className="twitter-share" href="#0"><i className="social_twitter"></i> Share</a></li>
                                        <li><a className="gplus-share" href="#0"><i className="social_googleplus"></i> Share</a></li>
                                    </ul>
                                </div>
                            </div>

                            {/* <!-- /row --> */}
                        </div>
                        {/* <!-- /container --> */}
                        <Modal
                            show={show}
                            onHide={(e) => handleClose(e)}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            keyboard={false}
                            className="widthModal"
                        // size='sm'
                        >
                            <Modal.Header>

                                <Modal.Title id="contained-modal-title-vcenter">Review</Modal.Title>
                                <Button
                                    onClick={(e) => handleClose(e)} className="borderBtn"
                                >
                                    <i className='icon_close'></i>
                                </Button>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="sign-in-wrapper">


                                </div>
                            </Modal.Body>

                        </Modal>

                    </main>}

                {/* <!-- /main --> */}

                <Footer />

                <div id="toTop"></div>
                {/* <!-- Back to top button --> */}

                <div className="layer"></div>
                {/* <!-- Opacity Mask Menu Mobile --> */}

                <div id="message">Item added to cart</div>
                {/* <!-- Add to cart message --> */}

                {/* <!-- Sign In Modal --> */}



                <Helmet>
                    <script src='assets/js/common_scripts.min.js' type="text/javascript"></script>
                    <script src='assets/js/common_func.js'></script>
                    <script src="assets/js/sticky_sidebar.min.js"></script>
                    <script src="assets/js/specific_state.js"></script>
                    <script src="assets/js/detail_restaurant.js"></script>
                </Helmet>


            </>
        </>
    );
}


export default ResDetail;