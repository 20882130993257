import React from 'react';
import './sidebar.scss';
import { useState, useEffect } from 'react';
import '../Header/header.css'
import axios from 'axios';
import useGeolocation from "react-hook-geolocation";
import { MapContainer } from './../GoogleMap/GoogleMap';
const Sidebar = () => {
  const latitude = localStorage.getItem('latitude')
  const longitude = localStorage.getItem('longitude')
  const [abreMenu, setabrevMenu] = useState(false);
  // const [latitude,setLatitude]=useState('')
  // const [location,setLocation]=useState(locationlocal)
  const geolocation = useGeolocation();
  const [hide, sethide] = useState('inputsearchshow')
  function handleClick(e) {
    e.preventDefault();
    setabrevMenu(!abreMenu)
    if (hide == "inputsearchshow") {
      sethide("inputsearchhidden")
    } else {
      sethide("inputsearchshow")
    }
  }
  async function currentLocation(e) {
    console.log(geolocation.latitude, 'latitude')
    window.location.reload(false);

    // e.preventDefault()
    // localStorage.setItem('latitude',geolocation.latitude)
    // localStorage.setItem('longitude',geolocation.longitude)
    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition(showPosition);
      navigator.geolocation.getCurrentPosition(function (position) {
        localStorage.setItem('latitude', position.coords.latitude)
        localStorage.setItem('longitude', position.coords.longitude)
        console.log(position, 'position')
      });

    }
    else {
      alert("Geolocation is not supported by this browser.")
    }

    setabrevMenu(!abreMenu)
    if (hide == "inputsearchshow") {
      sethide("inputsearchhidden")
    } else {
      sethide("inputsearchshow")
    }
  }

  // function getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(showPosition);
  //   } else { 
  //     x.innerHTML = "Geolocation is not supported by this browser.";
  //   }
  // }

  useEffect(() => {
    if ((latitude != null || latitude != undefined) && (longitude != null || longitude != undefined)) {
      setabrevMenu(false)
      sethide("inputsearchshow")
    }
    else {
      setabrevMenu(true)
      sethide("inputsearchhidden")
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      localStorage.setItem('latitude', position.coords.latitude)
      localStorage.setItem('longitude', position.coords.longitude)
      console.log(position, 'position')
    });
  }, [])
  return (
    <>
      <div className="container-menu">
        <button className={abreMenu ? "hamburguer hamburguer--open" : "hamburguer"}
          onClick={(e) => handleClick(e)}>
          <input type="text" className={"locationsearch form-control " + hide} placeholder='Select your Location' />
        </button>
        <div className={abreMenu ? "menu menu--open" : "menu"}>
          <div className="text-right">
            <button className='btninto' onClick={(e) => handleClick(e)}><i className='icon_close'></i></button>
          </div>
          <div className="container">
            <input type="text" className={"mtsearch form-control"} placeholder='Your Location' />
            {/* <MapContainer /> */}

            <button type='button' className=' btncolor btn bg-white form-control' onClick={(e) => currentLocation(e)}>Fetch your Current Location</button>
          </div>
        </div>

      </div>
    </>
  );
}

export default Sidebar;